import {createSlice} from '@reduxjs/toolkit';

const sosSclice = createSlice({
    name: 'sos',
    initialState: {
        loading: false,
        sos_list: [],
        sos_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setSos_list: (state, action) => {
            state.sos_list = action.payload
        },
        setSos_id: (state, action) => {
            state.sos_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setSos_list,
    setSos_id,
} = sosSclice.actions
export default sosSclice.reducer;