import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import exportFromJSON from 'export-from-json'
import { 
    getPayId,
    getPayList,
    getPayListSites,
    addPay,
    updPay
} from '../../../API/pays';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdOutlineNoPhotography } from 'react-icons/md';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';

export const PaysList = props => {
    const dispatch = useDispatch();

    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const pays = useSelector(state => state.pays);

    const [sites_list_search_name, setSites_list_search_name] = useState("");
    const [sites_list_search_engine, setSites_list_search_engine] = useState("");
    const [sites_list_search_copyright, setSites_list_search_copyright] = useState("");
    const [sites_list_search_status, setSites_list_search_status] = useState("publish");
    const [sites_list_search_country, setSites_list_search_country] = useState("");

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_site_list_search] = Form.useForm();
    const [form_site_add] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    form_site_list_search.setFieldsValue({ 
        search: sites_list_search_name, 
        engine: sites_list_search_engine, 
        copyright: sites_list_search_copyright,
        status: sites_list_search_status,
        country: sites_list_search_country,
    });

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getPayListSites({

        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getPayListSites]`, `color: #A366FF`, json );
            if (json?.code) {
                //Done
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get sites_list () {
            if ( pays.pay_list_sites.length > 0 ) {
                let list = pays.pay_list_sites;

                let search = sites_list_search_name.toLowerCase();
                if (search !== "") {
                    list = pays.pay_list_sites.filter( function( item ) {
                        return(
                            item['name'].toLowerCase().indexOf( search ) > -1 ||
                            item['excerpt'].toLowerCase().indexOf( search ) > -1 ||
                            item['domain'].toLowerCase().indexOf( search ) > -1
                        );
                    } );
                }

                if (sites_list_search_engine !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['engine'] === sites_list_search_engine
                        );
                    } );
                }

                if (sites_list_search_copyright !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['copyright'] === sites_list_search_copyright
                        );
                    } );
                }

                if (sites_list_search_status !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['status'] === sites_list_search_status
                        );
                    } );
                }

                if (sites_list_search_country !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['country'] === sites_list_search_country
                        );
                    } );
                }

                function compareMonths(a, b) {
                    a = moment.unix(a.date_contract).add('months', +a.months)
                    b = moment.unix(b.date_contract).add('months', +b.months)

                    if (a > b) return 1;
                    if (a == b) return 0;
                    if (a < b) return -1;
                }

                list = [...list].sort(compareMonths);

                return list;
            } else {
                return pays.pay_list_sites;
            }
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                 }
            ]
        }
    };

    async function onChangeSearch() {
        var v = form_site_list_search.getFieldsValue();

        console.log(v);

        if (v['search'] !== undefined) {
            setSites_list_search_name(v['search'].replace(/\s+/g,' '));
        }

        if (v['engine'] !== undefined) {
            setSites_list_search_engine(v['engine']);
        }

        if (v['copyright'] !== undefined) {
            setSites_list_search_copyright(v['copyright']);
        }

        if (v['status'] !== undefined) {
            setSites_list_search_status(v['status']);
        }

        if (v['country'] !== undefined) {
            setSites_list_search_country(v['country']);
        }

    }

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

    async function paysExport() {
        const data = obj.sites_list.map((item) => {
            let container = {...item};
            container.copyright = +item.copyright ? moment.unix(item.copyright).format("DD/MM/YYYY") : 'no';
            container.engine = obj.engine.find((find_item) => find_item.value === item.engine)?.label;
            container.country = obj.country.find((find_item) => find_item.value === item.country)?.label;
            container.date_contract = moment.unix(item.date_contract).format("DD/MM/YYYY");
            container.paid_up_to = moment.unix(item.date_contract).add('months', +item.months).format("DD/MM/YYYY");
            container.debt = moment.unix(item.date_contract).add('months', +item.months).unix() > moment().unix() ? 'There is no debt' : 'There is a debt';
            delete container.id;
            delete container.status;
            delete container.months;
            delete container.date_create;
            delete container.date_edit;
        
            return container;
        });

        const fileName = 'List of payments '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            name: "Site name",
            domain: "Domain name",
            engine: "Engine",
            excerpt: "Site Description",
            material: "Links to the site materials",
            features: "Site Features",
            contacts: "Website contact details",
            seo: "SEO data sites",
            form: "Where data is sent from the form",
            copyright: "Availability of copyright",
            country: "Country",
            date_contract: "Date of conclusion of the contract",
            paid_up_to: "Up to what date is the site paid for",
            debt: "Is there a debt or not"
        };

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <section className='container --pays_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Payments Section</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                <div className='left_form'>
                                    <Form name="form" className="default_form" form={form_site_list_search}>
                                        <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                            <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/`} ><span>Go back</span></Link>
                                        </div>
                                        <Form.Item key="search" name="search">
                                            <Input disabled={pays.loading} autoFocus className={`custom_input`} placeholder="Search by name" onChange={ () => onChangeSearch() } bordered={false} 
                                                prefix={
                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                }
                                            />
                                        </Form.Item>
                                        <label>A country</label>
                                        <Form.Item name={`country`} >
                                            <Select
                                                disabled={pays.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select a country"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.country}
                                            />
                                        </Form.Item>
                                        <label>Engine</label>
                                        <Form.Item name={`engine`} >
                                            <Select
                                                disabled={pays.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select Engine"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={
                                                    obj.engine
                                                }
                                            />
                                        </Form.Item>
                                        <label>Copyright</label>
                                        <Form.Item name={`copyright`} >
                                            <Select
                                                disabled={pays.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Is there a copyright or not"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.copyright}
                                            />
                                        </Form.Item>
                                        <label>Status</label>
                                        <Form.Item name={`status`} >
                                            <Select
                                                disabled={pays.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.status}
                                            />
                                        </Form.Item>
                                        <div className='form_buttons'>
                                            <Button className='submit' style={{width:'100%'}} onClick={() => paysExport()} loading={pays.loading}>Export to CSV</Button>
                                        </div>
                                    </Form>
                                </div>
                                <div className='right_content'>
                                    {pays.loading ?
                                        <Skeleton active />
                                    :
                                        <List itemLayout="vertical"
                                            size="large"
                                            pagination={{
                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                                onChange(current, pageSize) {
                                                    setPage(current);
                                                    setPaginationSize(pageSize)
                                                },
                                                defaultCurrent: page
                                            }}
                                            locale={{
                                                emptyText: 
                                                    <Empty 
                                                        description="The list of sites is empty"
                                                    />
                                            }}
                                            dataSource={obj.sites_list}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} >
                                                    <a target='_blank' href={`/pays/${item.id}`} >
                                                        <div className='left'>
                                                            <div className={`spec_item_left ${item.site_preview ? item.site_preview : 'no_photo'}`}>
                                                                {item.site_preview ? 
                                                                    <img src={`${API}/uploads/${item.site_preview}`} /> 
                                                                : 
                                                                    <MdOutlineNoPhotography />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className="org_name">
                                                                <span className="name">{item.name}</span>
                                                            </div>
                                                            <div className='org_bottom'>
                                                                <p className='last'>{item.excerpt}</p>
                                                            </div>
                                                            <div className='org_info'>
                                                                <p className='category'>
                                                                    <span style={{backgroundColor:moment.unix(item.date_contract).add('months', +item.months).unix() > moment().unix() ? '#52c41a' : '#f5222d'}}><b>Briefly about payment: </b>{moment.unix(item.date_contract).add('months', +item.months).unix() > moment().unix() ? 'There is no debt' : 'There is a debt'}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Paid up to: </b>{moment.unix(item.date_contract).add('months', +item.months).format("DD/MM/YYYY")}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === item.country)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === item.engine)?.label}</span>
                                                                </p><p className='experience'>
                                                                    <span><b>Date of copyright protection: </b>{+item.copyright ? moment.unix(item.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                                </p>
                                                                <p className='category'><span style={{backgroundColor:obj.status.find((find_item) => find_item.value === item.status)?.color}}>{obj.status.find((find_item) => find_item.value === item.status)?.label}</span><span>{item.domain}</span></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};