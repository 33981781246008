import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import { 
    getSitesId,
    updSite
} from '../../../API/sites';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdOutlineNoPhotography } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';
import { Error404_Component } from '../../errors/404';

const { TextArea } = Input;

export const SitesItem = props => {
    const dispatch = useDispatch();

    const params = useParams();
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const sites = useSelector(state => state.sites);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_site_data] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getSitesId({
            id:params.siteID
        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getSitesId]`, `color: #A366FF`, json );
            if (json?.code) {
                form_site_data.resetFields();

                form_site_data.setFieldsValue({
                    id: json?.result?.id,
                    name: json?.result?.name,
                    domain: json?.result?.domain,
                    engine: json?.result?.engine,
                    excerpt: json?.result?.excerpt,
                    material: json?.result?.material,
                    features: json?.result?.features,
                    contacts: json?.result?.contacts,
                    seo: json?.result?.seo,
                    form: json?.result?.form,
                    stage: json?.result?.stage,
                    country: json?.result?.country,
                    status: json?.result?.status,
                    date_contract:moment.unix(json?.result?.date_contract).format("DD.MM.YYYY"),
                });
                if (+json?.result?.copyright > 0) {
                    form_site_data.setFieldsValue({
                        copyright:moment.unix(json?.result?.copyright).format("DD.MM.YYYY"),
                    });
                }
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                 }
            ]
        },
        get stage () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'The layout is missing',
                    value: '0',
                    color: '#f5222d'    // Красный
                },
                {
                    label: 'The layout is received/in tasks',
                    value: '1',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'At work',
                    value: '2',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'On check with the designer',
                    value: '3',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the designer',
                    value: '4',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '5',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '6',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '7',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '8',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Passed',
                    value: '9',
                    color: '#52c41a'    // Зелёный
                },
            ]
        }
    };

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }
    

    // Отправка формы обновления сайта
    async function form_site_upd_finish(v) {
        if(v['date_contract']) { v['date_contract'] = Date.parse(moment(v['date_contract'],'DD.MM.YYYY').format())/1000; }
        if(v['copyright']) { v['copyright'] = Date.parse(moment(v['copyright'],'DD.MM.YYYY').format())/1000; }
        v['domain'] = v['domain'].replace(/https?:\/\//g, '').split('/')[0];
        form_site_data.setFieldsValue({domain:v['domain']})

        await updSite(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updSite]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The site has been updated');
                getSitesId({id:v.id},dispatch,profile);
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    function TimeInput(props) {
        let mask = 'dD.mM.YYYY';
        let formatChars = {
            'Y': '[0-9]',
            'd': '[0-3]',
            'D': '[0-9]',
            'm': '[0-1]',
            'M': '[1-9]'
        };
      
        let beforeMaskedValueChange = (newState, oldState, userInput) => {
            let { value } = newState;
        
            let dateParts = value.split('.');
            let dayPart = dateParts[0];
            let monthPart = dateParts[1];
        
            // Conditional mask for the 2nd digit of day based on the first digit
            if(dayPart.startsWith('3'))
                formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
            else if(dayPart.startsWith('0'))
                formatChars['D'] = '[1-9]'; // To block 00.
            else
                formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.
        
        
            // Conditional mask for the 2nd digit of month based on the first digit
            if(monthPart.startsWith('1'))
                formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
            else
                formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.
        
            return {value, selection: newState.selection};
        }
        return (
            <InputMask
                className='ant-input custom_input'
                mask={mask}
                value={props.value}
                onChange={props.onChange}
                formatChars={formatChars}
                beforeMaskedValueChange={beforeMaskedValueChange}>
            </InputMask>
        );
    }

    return (
        <>
            <section className='container --site_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Website questionnaire</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                {['3','4'].indexOf( profile?.data?.type ) !== -1 ?
                                    <div className='left_form'>
                                        {(!sites.loading && Object.keys(sites.site_id).length) ?
                                            <Form name="form" className="default_form" form={form_site_data} onFinish={form_site_upd_finish}>
                                                <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                                    <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/sites`} ><span>Go back</span></Link>
                                                </div>
                                                <Form.Item hidden name={`id`} rules={[{ required: true, message: `Please enter the ID!` }]}>
                                                    <Input disabled={sites.loading} className='custom_input' placeholder={`ID`} />
                                                </Form.Item>
                                                <label>Status</label>
                                                <Form.Item name={`status`} >
                                                    <Select
                                                        disabled={sites.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Select status"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.status.filter((status_item) => ['','none'].indexOf( status_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                <label>Title</label>
                                                <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]}>
                                                    <Input disabled={sites.loading} className='custom_input' placeholder={`Title`} />
                                                </Form.Item>
                                                <label>Domain</label>
                                                <Form.Item name={`domain`} rules={[{ required: true, message: `Please enter the domain name!` }]}>
                                                    <Input disabled={sites.loading} className='custom_input' placeholder={`domain.com`} />
                                                </Form.Item>
                                                <label>Country</label>
                                                <Form.Item name={`country`} rules={[{ required: true, message: `Please select a country!` }]} >
                                                    <Select
                                                        disabled={sites.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Select a country"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.country.filter((country_item) => ['','none'].indexOf( country_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                <label>Engine</label>
                                                <Form.Item name={`engine`} >
                                                    <Select
                                                        disabled={sites.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Select engine"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.engine.filter((engine_item) => ['','none'].indexOf( engine_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                <label>Copyright</label>
                                                <Form.Item name={`copyright`} >
                                                    <Select
                                                        disabled={sites.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Is there a copyright or not"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.copyright}
                                                    />
                                                </Form.Item>
                                                <label>Site Stage</label>
                                                <Form.Item name={`stage`} >
                                                    <Select
                                                        disabled={sites.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Select stage"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.stage.filter((engine_item) => ['','none'].indexOf( engine_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                <label>Description</label>
                                                <Form.Item name={`excerpt`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="Description" rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>References to materials</label>
                                                <Form.Item name={`material`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="References to materials" rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>Site Features</label>
                                                <Form.Item name={`features`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="Site Features" rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>Contact details</label>
                                                <Form.Item name={`contacts`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="Contact details" rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>SEO data</label>
                                                <Form.Item name={`seo`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="Metric counter number, headers" rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>Where does the data from the feedback forms go</label>
                                                <Form.Item name={`form`} >
                                                    <TextArea disabled={sites.loading} className='custom_input' placeholder="Email: ..." rows={4} showCount  maxLength={1000} />
                                                </Form.Item>
                                                <label>Date of copyright protection</label>
                                                <Form.Item name={`copyright`} >
                                                    <TimeInput
                                                        onChange={({ target: { value } }) => form_site_data.setFieldsValue({
                                                            copyright:value  
                                                        })}>
                                                    </TimeInput>
                                                </Form.Item>
                                                <label>Date of conclusion of the transaction</label>
                                                <Form.Item name={`date_contract`} >
                                                    <TimeInput
                                                        onChange={({ target: { value } }) => form_site_data.setFieldsValue({
                                                            date_contract:value  
                                                        })}>
                                                    </TimeInput>
                                                </Form.Item>
                                                
                                                <div className='form_buttons'>
                                                    <Button className='submit' style={{width:'100%'}} htmlType="submit" loading={sites.loading}>Save</Button>
                                                </div>
                                            </Form>
                                        :
                                            <Skeleton />
                                        }
                                    </div>
                                :
                                    null
                                }
                                <div className='right_content' style={{maxWidth:['3','4'].indexOf( profile?.data?.type ) !== -1 ? '':'100%'}}>
                                    {sites.loading ?
                                        <Skeleton active />
                                    :
                                        (!sites.loading && Object.keys(sites.site_id).length) ?
                                            <div className='info_data'>
                                                <div className='left'>
                                                    <div className={`spec_item_left ${sites.site_id.site_preview ? sites.site_id.site_preview : 'no_photo'}`}>
                                                        {sites.site_id.site_preview ? 
                                                            <img src={`${API}/uploads/${sites.site_id.site_preview}`} /> 
                                                        : 
                                                            <MdOutlineNoPhotography />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='right'>
                                                    <div className="org_name">
                                                        <span className="name">{sites.site_id?.name}</span>
                                                    </div>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === sites.site_id?.country)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === sites.site_id?.engine)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of copyright protection: </b>{+sites.site_id?.copyright ? moment.unix(sites.site_id?.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                        </p>
                                                        <p className='category'><span style={{backgroundColor:obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.color}}>{obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.label}</span><span style={{cursor:'pointer'}} onClick={() => window.open(`https://${sites.site_id?.domain}/`,'_blank')}>{sites.site_id?.domain}</span></p>
                                                    </div>
                                                </div>
                                                <div className='bottom'>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>Description: </b>{sites.site_id?.excerpt}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>References to materials: </b>{sites.site_id?.material}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Site Features: </b>{sites.site_id?.features}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Contact details: </b>{sites.site_id?.contacts}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>SEO data: </b>{sites.site_id?.seo}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Where does the data from the feedback forms go: </b>{sites.site_id?.form}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of conclusion of the transaction: </b>{moment.unix(sites.site_id?.date_contract).format("DD/MM/YYYY")}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of copyright protection: </b>{+sites.site_id?.copyright ? moment.unix(sites.site_id?.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <Error404_Component />
                                        }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};