import {createSlice} from '@reduxjs/toolkit';

const errorsSclice = createSlice({
    name: 'errors',
    initialState: {
        loading: false,
        error_list: [],
        error_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError_list: (state, action) => {
            state.error_list = action.payload
        },
        setError_id: (state, action) => {
            state.error_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setError_list,
    setError_id,
} = errorsSclice.actions
export default errorsSclice.reducer;