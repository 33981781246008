import {createSlice} from '@reduxjs/toolkit';

const sitesSclice = createSlice({
    name: 'posts',
    initialState: {
        loading: false,
        site_list: [],
        site_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setSite_list: (state, action) => {
            state.site_list = action.payload
        },
        setSite_id: (state, action) => {
            state.site_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setSite_list,
    setSite_id,
} = sitesSclice.actions
export default sitesSclice.reducer;