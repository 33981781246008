import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Empty, message, Skeleton, Form, Input, Popconfirm, Select, Button, DatePicker } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../../params';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import exportFromJSON from 'export-from-json'
import { 
    getErrorList,
    updErrorExecutor
} from '../../../../API/errors';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdOutlineDoNotDisturbOnTotalSilence, MdOutlineNoPhotography } from 'react-icons/md';
import { Error403_Component } from '../../../errors/403';

export const ErrorList = props => {
    const dispatch = useDispatch();

    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const errors = useSelector(state => state.errors);

    const [error_list_search_name, setError_list_search_name] = useState("");
    const [error_list_search_status, setError_list_search_status] = useState("not_occupied");
    const [error_list_search_country, setError_list_search_country] = useState("");
    const [error_list_search_domain, setError_list_search_domain] = useState("");
    const [error_list_search_importance, setError_list_search_importance] = useState("");
    const [error_list_search_date_start, setError_list_search_date_start] = useState(null);
    const [error_list_search_date_end, setError_list_search_date_end] = useState(null);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_error_list_search] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    form_error_list_search.setFieldsValue({ 
        search: error_list_search_name, 
        status: error_list_search_status,
        country: error_list_search_country,
        domain: error_list_search_domain,
        importance: error_list_search_importance,
        date_start: error_list_search_date_start !== null ? dayjs(error_list_search_date_start,'DD/MM/YYYY') : null,
        date_end: error_list_search_date_end !== null ? dayjs(error_list_search_date_end,'DD/MM/YYYY') : null,
    });

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getErrorList({

        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getErrorList]`, `color: #A366FF`, json );
            if (json?.code) {
                //Done
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get error_list () {
            if ( errors.error_list.length > 0 ) {
                let list = errors.error_list;

                let search = error_list_search_name.toLowerCase();
                if (search !== "") {
                    list = errors.error_list.filter( function( item ) {
                        return(
                            item['site_domain'].toLowerCase().indexOf( search ) > -1 ||
                            item['site_name'].toLowerCase().indexOf( search ) > -1 ||
                            item['site_excerpt'].toLowerCase().indexOf( search ) > -1
                        );
                    } );
                }

                if (error_list_search_status !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['dev_status'] === error_list_search_status
                        );
                    } );
                }

                if (error_list_search_country !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['site_country'] === error_list_search_country
                        );
                    } );
                }

                if (error_list_search_domain !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['site_domain'] === error_list_search_domain
                        );
                    } );
                }

                if (error_list_search_importance !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['importance'] === error_list_search_importance
                        );
                    } );
                }

                if (error_list_search_date_start !== null) {
                    list = list.filter( function( item ) {
                        return(
                            +item['date_end'] >= dayjs(error_list_search_date_start,['DD/MM/YYYY']).unix()
                        );
                    } );
                }

                if (error_list_search_date_end !== null) {
                    list = list.filter( function( item ) {
                        return(
                            (+item['dev_date_end'] > 0 && +item['dev_date_end'] < dayjs(error_list_search_date_end,['DD/MM/YYYY']).unix() + 86400) ||
                            (+item['dev_date_end'] <= 0 && +item['date_end'] < dayjs(error_list_search_date_end,['DD/MM/YYYY']).unix() + 86400)
                        );
                    } );
                }

                return list;
            } else {
                return errors.error_list;
            }
        },
        get domain () {
            let temp_list = errors.error_list.reduce((acc, item) => {
                if (acc.map[item.site_domain])
                  return acc;
            
                    acc.map[item.site_domain] = true;
                    acc.errorlist.push({
                        value: item.site_domain,
                        label: item.site_name
                    });
                    return acc; 
                }, {
                    map: {},
                    errorlist: []
                })
                .errorlist;
            temp_list.unshift({label:'Not selected',value:''});

            return temp_list    ;
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Not occupied',
                    value: 'not_occupied',
                    color: '#1a7ec4'
                },
                {
                    label: 'Occupied',
                    value: 'occupied',
                    color: '#1a7ec4'
                },
                {
                    label: 'Does not require verification',
                    value: 'not_require_verification',
                    color: '#52c41a'
                },
                {
                    label: 'The problem cannot be fixed',
                    value: 'cannot_be_fixed',
                    color: '#f5222d'
                },
                {
                    label: 'No problem',
                    value: 'no_error',
                    color: '#52c41a'
                },
                {
                    label: 'Fixed',
                    value: 'fixed',
                    color: '#52c41a'
                }
            ]
        },
        get times () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Weekly',
                    value: 'weekly',
                },
                {
                    label: 'Monthly',
                    value: 'monthly',
                }
            ]
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get importance () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Weak',
                    value: '1',
                    color: '#f5db22'
                },
                {
                    label: 'Average',
                    value: '2',
                    color: '#f59022'
                },
                {
                    label: 'High',
                    value: '3',
                    color: '#f5222d'
                },
            ]
        },
    };

    async function onChangeSearch() {
        var v = form_error_list_search.getFieldsValue();

        console.log(v);

        if (v['search'] !== undefined) {
            setError_list_search_name(v['search'].replace(/\s+/g,' '));
        }

        if (v['status'] !== undefined) {
            setError_list_search_status(v['status']);
        }

        if (v['country'] !== undefined) {
            setError_list_search_country(v['country']);
        }

        if (v['domain'] !== undefined) {
            setError_list_search_domain(v['domain']);
        }

        if (v['importance'] !== undefined) {
            setError_list_search_importance(v['importance']);
        }

        if (v['date_start'] !== null) {
            setError_list_search_date_start(dayjs(v['date_start']['$d']).format('DD/MM/YYYY'));
        } else {
            setError_list_search_date_start(null);
        }

        if (v['date_end'] !== null) {
            setError_list_search_date_end(dayjs(v['date_end']['$d']).format('DD/MM/YYYY'));
        } else {
            setError_list_search_date_end(null);
        }
    }

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

    function getTimeSec(sec) {
        let out = '';

        let minutes = Math.floor(sec / 60);
        let seconds = +sec - (minutes * 60);

        if (minutes) {
            out += `${minutes} ${decnum(minutes,[ 'minute','minutes','minutes' ])} `;
        }

        if (seconds) {
            out += `${seconds} ${decnum(seconds,[ 'second','seconds','seconds' ])}`;
        }

        if (out == '') {
            out += `it is impossible to calculate the time`;
        }

        return out;
    };

    async function changeExecutor(v) {
        await updErrorExecutor(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updErrorExecutor]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The task has been updated');
                getErrorList({},dispatch,profile)
                if (v['dev_status'] === 'occupied') {
                    //window.open(`/reports/errors/${v.id}`,'_blank');
                }
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function errorListExport() {
        let temp_time = 0;

        let data = obj.error_list.map((item, index) => {
            temp_time += +item.dev_time;

            let container = {...item};
            container.date_end = +item.date_end ? `${moment.unix(item.date_end).format('DD/MM/YYYY HH:mm')}` : '';
            container.importance = obj.importance.find((find_item) => find_item.value === item.importance)?.label;
            container.executor =  item.dev_executor ? [item.dev_executor_firstname,item.dev_executor_lastname].join(' ').trim() : '-';
            container.status = obj.status.find((find_item) => find_item.value === item.dev_status)?.label;
            container.dev_comment =  item.dev_comment ? item.dev_comment : '';
            container.comment =  item.comment ? item.comment : '';
            container.dev_date_start = +item.dev_date_start ? `${moment.unix(item.dev_date_start).format('DD/MM/YYYY HH:mm')}` : '';
            container.dev_date_end = +item.dev_date_end ? `${moment.unix(item.dev_date_end).format('DD/MM/YYYY HH:mm')}` : '';
            container.dev_time = getTimeSec(item.dev_time ?? 0);
            delete container.id;
        
            return container;
        });

        const fileName = 'Errors on websites '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            name: "Error name",
            excerpt: "Error Description",
            site_name: "Site name",
            site_domain: "Domain name",
            comment: "Tester's Comment",
            executor: "Developer",
            dev_comment: "Developer's Comment",
            importance: "Importance",
            status: "Status",
            date_end: "Date of discovery",
            dev_date_start: "Start date",
            dev_date_end: "Completion date",
            dev_time: "Time spent",
        };

        data.unshift({
            name: "",
            excerpt: "",
            site_name: "",
            site_domain: "",
            comment: "",
            executor: "",
            dev_comment: "",
            importance: "",
            status: "",
            date_end: "",
            dev_date_start: "",
            dev_date_end: "",
            dev_time: "",
        });

        data.unshift({
            name: "Time spent",
            excerpt: "",
            site_name: "",
            site_domain: "",
            comment: "",
            executor: "",
            dev_comment: "",
            importance: "",
            status: "",
            date_end: "",
            dev_date_start: "",
            dev_date_end: "",
            dev_time: getTimeSec(temp_time ?? 0),
        });

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <section className='container --error_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Errors on websites</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                <div className='left_form'>
                                    <Form name="form" className="default_form" form={form_error_list_search}>
                                        <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                            <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/`} ><span>Go back</span></Link>
                                        </div>
                                        <Form.Item key="search" name="search">
                                            <Input disabled={errors.loading} autoFocus className={`custom_input`} placeholder="Search by site name" onChange={ () => onChangeSearch() } bordered={false} 
                                                prefix={
                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                }
                                            />
                                        </Form.Item>
                                        <label>A country</label>
                                        <Form.Item name={`country`} >
                                            <Select
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select a country"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.country}
                                            />
                                        </Form.Item>
                                        <label>Website</label>
                                        <Form.Item name={`domain`} >
                                            <Select
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Choosing a Website"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.domain}
                                            />
                                        </Form.Item>
                                        <label>Importance</label>
                                        <Form.Item name={`importance`} >
                                            <Select
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select importance"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.importance}
                                            />
                                        </Form.Item>
                                        <label>Status</label>
                                        <Form.Item name={`status`} >
                                            <Select
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.status}
                                            />
                                        </Form.Item>
                                        <label>Start date</label>
                                        <Form.Item name={`date_start`} >
                                            <DatePicker
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className='custom_input' 
                                                format={['DD/MM/YYYY']} 
                                            />
                                        </Form.Item>
                                        <label>End date</label>
                                        <Form.Item name={`date_end`} >
                                            <DatePicker 
                                                disabled={errors.loading}
                                                onChange={() => onChangeSearch()}
                                                className='custom_input' 
                                                format={['DD/MM/YYYY']} 
                                            />
                                        </Form.Item>
                                        <div className='form_buttons' style={{marginTop:0}}>
                                            <Button className='submit' style={{width:'100%'}} onClick={() => errorListExport()} loading={errors.loading}>Export to CSV</Button>
                                        </div>
                                    </Form>
                                </div>
                                <div className='right_content'>
                                    {errors.loading ?
                                        <Skeleton active />
                                    :
                                        <List itemLayout="vertical"
                                            size="large"
                                            pagination={{
                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                                onChange(current, pageSize) {
                                                    setPage(current);
                                                    setPaginationSize(pageSize)
                                                },
                                                defaultCurrent: page
                                            }}
                                            locale={{
                                                emptyText: 
                                                    <Empty 
                                                        description="The task list is empty"
                                                    />
                                            }}
                                            dataSource={obj.error_list}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} >
                                                    <a style={{cursor:'default'}}>
                                                        <div className='left'>
                                                            <div className={`spec_item_left ${item.site_preview ? item.site_preview : 'no_photo'}`}>
                                                                {item.site_preview ? 
                                                                    <img src={`${API}/uploads/${item.site_preview}`} /> 
                                                                : 
                                                                    <MdOutlineNoPhotography />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className="org_name">
                                                                <span className="name">{item.name} from {moment.unix(item.date_end).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                            <div className='org_info' style={{marginTop:8}}>
                                                                <p className='experience'>
                                                                    <span><b>Domain: </b>{item.site_name}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Description: </b>{item.site_excerpt}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === item.site_country)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === item.site_engine)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Date of copyright protection: </b>{+item.site_copyright ? moment.unix(item.site_copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Importance: </b>{obj.importance.find((find_item) => find_item.value === item.importance)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Comment: </b>{item.comment}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Dev comment: </b>{item.dev_comment}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Executor: </b>{item.dev_executor ? [item.dev_executor_firstname,item.dev_executor_lastname].join(' ').trim() : 'missing'}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>It took time to error: </b>{getTimeSec(item.dev_time ?? 0)}</span>
                                                                </p>
                                                                {+item.dev_date_start ? 
                                                                    <p className='experience'>
                                                                        <span><b>Start of work: </b> {moment.unix(item.dev_date_start).format("DD/MM/YYYY HH:mm")}</span>
                                                                    </p>
                                                                :
                                                                    null
                                                                }
                                                                {+item.dev_date_end ? 
                                                                    <p className='experience'>
                                                                        <span><b>Completion of works: </b> {moment.unix(item.dev_date_end).format("DD/MM/YYYY HH:mm")}</span>
                                                                    </p>
                                                                :
                                                                    null
                                                                }
                                                                <p className='category'>
                                                                    <span style={{backgroundColor:obj.importance.find((find_item) => find_item.value === item.importance)?.color}}>
                                                                        {obj.importance.find((find_item) => find_item.value === item.importance)?.label}
                                                                    </span>
                                                                    <span style={{backgroundColor:obj.status.find((find_item) => find_item.value === item.dev_status)?.color}}>
                                                                        {obj.status.find((find_item) => find_item.value === item.dev_status)?.label}
                                                                    </span>
                                                                    <span>
                                                                        {item.site_domain}
                                                                    </span>
                                                                </p>
                                                                <p className='category'>
                                                                    {item.dev_status === 'not_occupied' ?
                                                                        <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => changeExecutor({
                                                                            id: item.id,
                                                                            dev_executor: profile.data.id,
                                                                            dev_status: 'occupied'
                                                                        })}>
                                                                            Take a task
                                                                        </span>
                                                                    :
                                                                        item.dev_executor === profile.data.id ?
                                                                            item.dev_status === 'occupied'  ?
                                                                                <>
                                                                                    <Popconfirm
                                                                                        title="Cancel this error"
                                                                                        description="Do you really want to perform this action?"
                                                                                        onConfirm={() => changeExecutor({
                                                                                            id: item.id,
                                                                                            dev_executor: profile.data.id,
                                                                                            dev_status: 'not_occupied'
                                                                                        })}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <span style={{cursor:'pointer',backgroundColor:'#f5222d'}} >
                                                                                            Cancel
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                </>
                                                                            :
                                                                                null
                                                                        :
                                                                            null
                                                                    }  
                                                                    <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => window.open(`/reports/errors/${item.id}`, '_blank')}>
                                                                        Open the error page
                                                                    </span>  
                                                                    <span style={{cursor:'pointer'}} onClick={() => window.open(`https://${item.site_domain}/`, '_blank')}>
                                                                        Visit the website
                                                                    </span>   
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};