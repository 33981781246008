import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingCheck, setCheck_list, setCheck_id } from "../redux/check/reducer";
import Request, { RequestFile } from "./handler.js";

export const getCheckList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingCheck(true));
    data.method = 'check_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setCheck_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingCheck(false));

    return result;
}

export const getCheckId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingCheck(true));
    data.method = 'check_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setCheck_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingCheck(false));

    return result;
}

export const updCheckExecutor = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingCheck(true));
    data.method = 'check_executor_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingCheck(false));

    return result;
}

export const updCheckItem = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingCheck(true));
    data.method = 'check_item_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingCheck(false));

    return result;
}

export const delCheckFile = async (data,dispatch,profile) => {
    var result = {};
    
    data.method = 'check_file_del';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });

    return result;
}