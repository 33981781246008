import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Empty, message, Skeleton, Form, Input, Popconfirm, Select, Button, DatePicker } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../../params';
import exportFromJSON from 'export-from-json'
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { 
    getCheckList,
    updCheckExecutor
} from '../../../../API/check';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdOutlineDoNotDisturbOnTotalSilence, MdOutlineNoPhotography } from 'react-icons/md';
import { Error403_Component } from '../../../errors/403';

export const CheckList = props => {
    const dispatch = useDispatch();

    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const check = useSelector(state => state.check);

    const [check_list_search_name, setCheck_list_search_name] = useState("");
    const [check_list_search_status, setCheck_list_search_status] = useState("not_occupied");
    const [check_list_search_country, setCheck_list_search_country] = useState("");
    const [check_list_search_domain, setCheck_list_search_domain] = useState("");
    const [check_list_search_days, setCheck_list_search_days] = useState("");
    const [check_list_search_checklist, setCheck_list_search_checklist] = useState("");
    const [check_list_search_date_start, setCheck_list_search_date_start] = useState(null);
    const [check_list_search_date_end, setCheck_list_search_date_end] = useState(null);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_check_list_search] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    form_check_list_search.setFieldsValue({ 
        search: check_list_search_name, 
        status: check_list_search_status,
        country: check_list_search_country,
        domain: check_list_search_domain,
        days: check_list_search_days,
        checklist: check_list_search_checklist,
        date_start: check_list_search_date_start !== null ? dayjs(check_list_search_date_start,'DD/MM/YYYY') : null,
        date_end: check_list_search_date_end !== null ? dayjs(check_list_search_date_end,'DD/MM/YYYY') : null,
    });

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getCheckList({

        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getCheckList]`, `color: #A366FF`, json );
            if (json?.code) {
                //Done
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get check_list () {
            if ( check.check_list.length > 0 ) {
                let list = check.check_list;

                let search = check_list_search_name.toLowerCase();
                if (search !== "") {
                    list = check.check_list.filter( function( item ) {
                        return(
                            item['site_domain'].toLowerCase().indexOf( search ) > -1 ||
                            item['site_name'].toLowerCase().indexOf( search ) > -1 ||
                            item['site_excerpt'].toLowerCase().indexOf( search ) > -1
                        );
                    } );
                }

                if (check_list_search_status !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['status'] === check_list_search_status
                        );
                    } );
                }

                if (check_list_search_country !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['site_country'] === check_list_search_country
                        );
                    } );
                }

                if (check_list_search_domain !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['site_domain'] === check_list_search_domain
                        );
                    } );
                }

                if (check_list_search_checklist !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['checklist_id'] === check_list_search_checklist
                        );
                    } );
                }

                if (check_list_search_date_start !== null) {
                    list = list.filter( function( item ) {
                        return(
                            +item['date_create'] >= dayjs(check_list_search_date_start,['DD/MM/YYYY']).unix()
                        );
                    } );
                }

                if (check_list_search_date_end !== null) {
                    list = list.filter( function( item ) {
                        return(
                            (+item['date_end'] > 0 && +item['date_end'] < dayjs(check_list_search_date_end,['DD/MM/YYYY']).unix() + 86400) ||
                            (+item['date_end'] <= 0 && +item['date_create'] < dayjs(check_list_search_date_end,['DD/MM/YYYY']).unix() + 86400)
                        );
                    } );
                }

                if (check_list_search_days !== "") {
                    list = list.filter( function( item ) {
                        return(
                            moment.unix(item.date_create).format("DD/MM/YYYY") === check_list_search_days
                        );
                    } );
                }

                return list;
            } else {
                return check.check_list;
            }
        },
        get checklists_name () {
            let temp_list = check.check_list.reduce((acc, item) => {
                if (acc.map[item.checklist_id])
                  return acc;
            
                    acc.map[item.checklist_id] = true;
                    acc.checklist.push({
                        value: item.checklist_id,
                        label: item.checklist_name
                    });
                    return acc; 
                }, {
                    map: {},
                    checklist: []
                })
                .checklist;
            temp_list.unshift({label:'Not selected',value:''});

            return temp_list    ;
        },
        get domain () {
            let temp_list = check.check_list.reduce((acc, item) => {
                if (acc.map[item.site_domain])
                  return acc;
            
                    acc.map[item.site_domain] = true;
                    acc.errorlist.push({
                        value: item.site_domain,
                        label: item.site_name
                    });
                    return acc; 
                }, {
                    map: {},
                    errorlist: []
                })
                .errorlist;
            temp_list.unshift({label:'Not selected',value:''});

            return temp_list    ;
        },
        get days () {
            let temp_list = check.check_list.reduce((acc, item) => {
                if (acc.map[moment.unix(item.date_create).format("DD/MM/YYYY")])
                  return acc;
            
                    acc.map[moment.unix(item.date_create).format("DD/MM/YYYY")] = true;
                    acc.errorlist.push({
                        value: moment.unix(item.date_create).format("DD/MM/YYYY"),
                        label: moment.unix(item.date_create).format("DD/MM/YYYY")
                    });
                    return acc; 
                }, {
                    map: {},
                    errorlist: []
                })
                .errorlist;
            temp_list.unshift({label:'Not selected',value:''});

            return temp_list    ;
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Not occupied',
                    value: 'not_occupied',
                    color: '#52c41a'
                },
                {
                    label: 'Occupied',
                    value: 'occupied',
                    color: '#1a7ec4'
                },
                {
                    label: 'Completed',
                    value: 'completed',
                    color: '#52c41a'
                },
            ]
        },
        get times () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Weekly',
                    value: 'weekly',
                },
                {
                    label: 'Monthly',
                    value: 'monthly',
                }
            ]
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
    };

    async function onChangeSearch() {
        var v = form_check_list_search.getFieldsValue();

        console.log(v);

        if (v['search'] !== undefined) {
            setCheck_list_search_name(v['search'].replace(/\s+/g,' '));
        }

        if (v['status'] !== undefined) {
            setCheck_list_search_status(v['status']);
        }

        if (v['country'] !== undefined) {
            setCheck_list_search_country(v['country']);
        }

        if (v['domain'] !== undefined) {
            setCheck_list_search_domain(v['domain']);
        }

        if (v['checklist'] !== undefined) {
            setCheck_list_search_checklist(v['checklist']);
        }

        if (v['date_start'] !== null) {
            setCheck_list_search_date_start(dayjs(v['date_start']['$d']).format('DD/MM/YYYY'));
        } else {
            setCheck_list_search_date_start(null);
        }

        if (v['date_end'] !== null) {
            setCheck_list_search_date_end(dayjs(v['date_end']['$d']).format('DD/MM/YYYY'));
        } else {
            setCheck_list_search_date_end(null);
        }

        if (v['days'] !== undefined) {
            setCheck_list_search_days(v['days']);
        }
    }

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

    function getTimeSec(sec) {
        let out = '';

        let minutes = Math.floor(sec / 60);
        let seconds = +sec - (minutes * 60);

        if (minutes) {
            out += `${minutes} ${decnum(minutes,[ 'minute','minutes','minutes' ])} `;
        }

        if (seconds) {
            out += `${seconds} ${decnum(seconds,[ 'second','seconds','seconds' ])}`;
        }

        if (out == '') {
            out += `it is impossible to calculate the time`;
        }

        return out;
    };

    async function changeExecutor(v) {
        await updCheckExecutor(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updCheckExecutor]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The task has been updated');
                getCheckList({},dispatch,profile)
                if (v['status'] === 'occupied') {
                    window.open(`/reports/check/${v.task_id}`,'_blank');
                }
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function checkExport() {
        let temp_time = 0;
        let temp_full_time = 0;

        let data = obj.check_list.map((item, index) => {
            temp_time += +item.time;
            temp_full_time += +item.full_time;

            let container = {...item};
            container.site =  `${item.site_name} (${item.site_domain})`;
            container.checklist_name =  item.checklist_name ? item.checklist_name : '';
            container.checklist_time = obj.times.find((find_item) => find_item.value === item.checklist_time)?.label;
            container.date_create = +item.date_create ? `${moment.unix(item.date_create).format('DD/MM/YYYY')}` : '';
            container.date_start = +item.date_start ? `${moment.unix(item.date_start).format('DD/MM/YYYY HH:mm')}` : '';
            container.date_end = +item.date_end ? `${moment.unix(item.date_end).format('DD/MM/YYYY HH:mm')}` : '';
            container.executor =  item.executor ? [item.executor_firstname,item.executor_lastname].join(' ').trim() : '-';
            container.status = obj.status.find((find_item) => find_item.value === item.status)?.label;
            container.time = getTimeSec(item.time ?? 0);
            container.full_time = getTimeSec(item.full_time ?? 0);
            delete container.id;
        
            return container;
        });

        const fileName = 'List of checks '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            checklist_name: "Checklist",
            checklist_excerpt: "Description",
            checklist_time: "Frequency",
            full_time: "Minimum time",
            site: "Site",
            executor: "Executor",
            status: "Status",
            date_create: "Day",
            date_start: "Start date",
            date_end: "Completion date",
            time: "Time spent",
        };

        data.unshift({
            checklist_name: "",
            checklist_excerpt: "",
            checklist_time: "",
            full_time: "",
            site: "",
            executor: "",
            status: "",
            date_create: "",
            date_start: "",
            date_end: "",
            time: "",
        });

        data.unshift({
            checklist_name: "Minimum time",
            checklist_excerpt: "",
            checklist_time: "",
            full_time: "",
            site: "",
            executor: "",
            status: "",
            date_create: "",
            date_start: "",
            date_end: "",
            time: getTimeSec(temp_full_time ?? 0),
        });

        data.unshift({
            checklist_name: "Time spent",
            checklist_excerpt: "",
            checklist_time: "",
            full_time: "",
            site: "",
            executor: "",
            status: "",
            date_create: "",
            date_start: "",
            date_end: "",
            time: getTimeSec(temp_time ?? 0),
        });

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <section className='container --check_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Checking sites</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                <div className='left_form'>
                                    <Form name="form" className="default_form" form={form_check_list_search}>
                                        <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                            <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/`} ><span>Go back</span></Link>
                                        </div>
                                        <Form.Item key="search" name="search">
                                            <Input disabled={check.loading} autoFocus className={`custom_input`} placeholder="Search by name" onChange={ () => onChangeSearch() } bordered={false} 
                                                prefix={
                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                }
                                            />
                                        </Form.Item>
                                        <label>A country</label>
                                        <Form.Item name={`country`} >
                                            <Select
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select a country"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.country}
                                            />
                                        </Form.Item>
                                        <label>Website</label>
                                        <Form.Item name={`domain`} >
                                            <Select
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Choosing a Website"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.domain}
                                            />
                                        </Form.Item>
                                        <label>Day</label>
                                        <Form.Item name={`days`} >
                                            <Select
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Choosing a Website"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.days}
                                            />
                                        </Form.Item>
                                        <label>Checklist</label>
                                        <Form.Item name={`checklist`} >
                                            <Select
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Choosing a checklist"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.checklists_name}
                                            />
                                        </Form.Item>
                                        <label>Status</label>
                                        <Form.Item name={`status`} >
                                            <Select
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.status}
                                            />
                                        </Form.Item>
                                        <label>Start date</label>
                                        <Form.Item name={`date_start`} >
                                            <DatePicker
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className='custom_input' 
                                                format={['DD/MM/YYYY']} 
                                            />
                                        </Form.Item>
                                        <label>End date</label>
                                        <Form.Item name={`date_end`} >
                                            <DatePicker 
                                                disabled={check.loading}
                                                onChange={() => onChangeSearch()}
                                                className='custom_input' 
                                                format={['DD/MM/YYYY']} 
                                            />
                                        </Form.Item>
                                        <div className='form_buttons' style={{marginTop:0}}>
                                            <Button className='submit' style={{width:'100%'}} onClick={() => checkExport()} loading={check.loading}>Export to CSV</Button>
                                        </div>
                                    </Form>
                                </div>
                                <div className='right_content'>
                                    {check.loading ?
                                        <Skeleton active />
                                    :
                                        <List itemLayout="vertical"
                                            size="large"
                                            pagination={{
                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                                onChange(current, pageSize) {
                                                    setPage(current);
                                                    setPaginationSize(pageSize)
                                                },
                                                defaultCurrent: page
                                            }}
                                            locale={{
                                                emptyText: 
                                                    <Empty 
                                                        description="The task list is empty"
                                                    />
                                            }}
                                            dataSource={obj.check_list}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} >
                                                    <a style={{cursor:'default'}}>
                                                        <div className='left'>
                                                            <div className={`spec_item_left ${item.site_preview ? item.site_preview : 'no_photo'}`}>
                                                                {item.site_preview ? 
                                                                    <img src={`${API}/uploads/${item.site_preview}`} /> 
                                                                : 
                                                                    <MdOutlineNoPhotography />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className="org_name">
                                                                <span className="name">{item.checklist_name} from {moment.unix(item.date_create).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                            <div className='org_info' style={{marginTop:8}}>
                                                                <p className='experience'>
                                                                    <span><b>Domain: </b>{item.site_name}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Description: </b>{item.site_excerpt}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === item.site_country)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === item.site_engine)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Date of copyright protection: </b>{+item.site_copyright ? moment.unix(item.site_copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Executor: </b>{item.executor ? [item.executor_firstname,item.executor_lastname].join(' ').trim() : 'missing'}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>It took time to check: </b>{getTimeSec(item.time ?? 0)}</span>
                                                                </p>
                                                                {+item.date_start ? 
                                                                    <p className='experience'>
                                                                        <span><b>Start of work: </b> {moment.unix(item.date_start).format("DD/MM/YYYY HH:mm")}</span>
                                                                    </p>
                                                                :
                                                                    null
                                                                }
                                                                {+item.date_end ? 
                                                                    <p className='experience'>
                                                                        <span><b>Completion of works: </b> {moment.unix(item.date_end).format("DD/MM/YYYY HH:mm")}</span>
                                                                    </p>
                                                                :
                                                                    null
                                                                }
                                                                <p className='category'>
                                                                    <span style={{backgroundColor:obj.status.find((find_item) => find_item.value === item.status)?.color}}>
                                                                        {obj.status.find((find_item) => find_item.value === item.status)?.label}
                                                                    </span>
                                                                    <span>
                                                                        {item.site_domain}
                                                                    </span>
                                                                </p>
                                                                <p className='category'>
                                                                    {item.status === 'not_occupied' ?
                                                                        <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => changeExecutor({
                                                                            task_id: item.id,
                                                                            executor: profile.data.id,
                                                                            status: 'occupied'
                                                                        })}>
                                                                            Take a task
                                                                        </span>
                                                                    :
                                                                        item.executor === profile.data.id ?
                                                                            item.status !== 'completed'  ?
                                                                                <>
                                                                                    <Popconfirm
                                                                                        title="Complete Execution"
                                                                                        description="Do you really want to perform this action?"
                                                                                        onConfirm={() => changeExecutor({
                                                                                            task_id: item.id,
                                                                                            executor: profile.data.id,
                                                                                            status: 'completed'
                                                                                        })}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} >
                                                                                            Complete Execution
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                    <Popconfirm
                                                                                        title="Cancel this check"
                                                                                        description="Do you really want to perform this action?"
                                                                                        onConfirm={() => changeExecutor({
                                                                                            task_id: item.id,
                                                                                            executor: profile.data.id,
                                                                                            status: 'not_occupied'
                                                                                        })}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <span style={{cursor:'pointer',backgroundColor:'#f5222d'}} >
                                                                                            Cancel verification
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                </>
                                                                            :
                                                                                null
                                                                        :
                                                                            null
                                                                    }  
                                                                    <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => window.open(`/reports/check/${item.id}`, '_blank')}>
                                                                        Open the site verification page
                                                                    </span>  
                                                                    <span style={{cursor:'pointer'}} onClick={() => window.open(`https://${item.site_domain}/`, '_blank')}>
                                                                        Visit the website
                                                                    </span>   
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};