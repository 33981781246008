import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingProfile, setAuth, setData, setRegistration, setToken, setUsers } from "../redux/profile/reducer";
import Request from "./handler.js";

export const getUsers = async (data,dispatch,profile) => {
    var result = {};
    
    data.method = 'users';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setUsers(json?.result ? json?.result : []));
        }

        result = json;
    });

    return result;
}

export const registration = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoading(true));
    data.method = 'registration';

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setRegistration(json?.result ? json?.result : {}));
        }

        result = json;
    });
    await dispatch(setLoading(false));

    return result;
}

export const authorization = async (data,dispatch,profile) => {
    var result = {};

    await dispatch(setLoading(true));
    data.method = 'authorization';

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            await dispatch(setToken(json?.result?.token));
        }

        result = json;
    });
    await dispatch(setLoading(false));

    return result;
}

export const forgot = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'forgot';

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const updProfilePassword = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_upd_password'

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const forgot_code = async (data,dispatch) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'forgot_code';

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const forgot_password = async (data,dispatch) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'forgot_password';

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const confirmProfileEmail = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_confirm_email';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const confirmProfileEmailCode = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_confirm_email_code';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const confirmProfilePhone = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_confirm_phone';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const confirmProfilePhoneCode = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_confirm_phone_code';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const getProfile = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            await dispatch(setAuth(1));
            await dispatch(setData(json?.data ? json?.data : {}));
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const updUser = async (data,dispatch,profile) => {
    var result = {};
    
    data.method = 'user_upd';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            //Done
        }

        result = json;
    });

    return result;
}

export const updProfile = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_upd';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const updProfilePhone = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_upd_phone';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}

export const updProfileEmail = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingProfile(true));
    data.method = 'profile_upd_email';

    if (profile.token) {
        data.token = profile.token;
    }

    await Request(data, 'post')
    .then(async json => {
        
        if (json?.code) {
            //Done
        }

        result = json;
    });
    await dispatch(setLoadingProfile(false));

    return result;
}