import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, InputNumber, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import exportFromJSON from 'export-from-json'
import { 
    getSitesId,
    updSiteDateContract
} from '../../../API/sites';
import { 
    addPay,
    getPayList,
    updPay
} from '../../../API/pays';
import {
    PlusOutlined
} from '@ant-design/icons';
import { MdOutlineNoPhotography } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';
import { Error404_Component } from '../../errors/404';

const { TextArea } = Input;

export const PaysItem = props => {
    const dispatch = useDispatch();

    const params = useParams();
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const sites = useSelector(state => state.sites);
    const pays = useSelector(state => state.pays);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_site_date_contract_data] = Form.useForm();
    const [form_site_pay_add] = Form.useForm();
    const [form_site_pay_data] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getSitesId({
            id:params.siteID
        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getSitesId]`, `color: #A366FF`, json );
            if (json?.code) {
                form_site_date_contract_data.resetFields();

                form_site_date_contract_data.setFieldsValue({
                    id: json?.result?.id,
                    date_contract:moment.unix(json?.result?.date_contract).format("DD.MM.YYYY"),
                });

                getPayList({
                    site_id:params.siteID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get pays () {
            return pays.pay_list;
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                }
            ]
        }
    };

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }
    

    // Отправка формы обновления сайта
    async function form_site_date_contract_upd_finish(v) {
        if(v['date_contract']) { v['date_contract'] = Date.parse(moment(v['date_contract'],'DD.MM.YYYY').format())/1000; }

        await updSiteDateContract(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updSite]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The site has been updated');
                await getSitesId({
                    id:v.id
                },dispatch,profile)
                .then(async json => {
                    console.log ( `%c [API]: [getSitesId]`, `color: #A366FF`, json );
                    if (json?.code) {
                        form_site_date_contract_data.resetFields();
        
                        form_site_date_contract_data.setFieldsValue({
                            id: json?.result?.id,
                            date_contract:moment.unix(json?.result?.date_contract).format("DD.MM.YYYY"),
                        });
                    } else {
                        let error = [];
                        if (json.error[0]) {
                            error.push(json.error[0]);
                        }
                        if (json.error[1]) {
                            error.push(json.error[1]);
                        }
                        if (json.error[3]) {
                            error.push((json.error[3]).join(', '));
                        }
                        message.error(error.join('. '));
                    }
                });
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    function TimeInput(props) {
        let mask = 'dD.mM.YYYY';
        let formatChars = {
            'Y': '[0-9]',
            'd': '[0-3]',
            'D': '[0-9]',
            'm': '[0-1]',
            'M': '[1-9]'
        };
      
        let beforeMaskedValueChange = (newState, oldState, userInput) => {
            let { value } = newState;
        
            let dateParts = value.split('.');
            let dayPart = dateParts[0];
            let monthPart = dateParts[1];
        
            // Conditional mask for the 2nd digit of day based on the first digit
            if(dayPart.startsWith('3'))
                formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
            else if(dayPart.startsWith('0'))
                formatChars['D'] = '[1-9]'; // To block 00.
            else
                formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.
        
        
            // Conditional mask for the 2nd digit of month based on the first digit
            if(monthPart.startsWith('1'))
                formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
            else
                formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.
        
            return {value, selection: newState.selection};
        }
        return (
            <InputMask
                className='ant-input custom_input'
                mask={mask}
                value={props.value}
                onChange={props.onChange}
                formatChars={formatChars}
                beforeMaskedValueChange={beforeMaskedValueChange}>
            </InputMask>
        );
    }

    async function show_site_pay_add() {
        form_site_pay_add.resetFields();
        dispatch(updPanel('site_pay_add'));
    }

    async function hide_site_pay_add() {
        form_site_pay_add.resetFields();
        dispatch(updPanel('site_pay_add'));
    }

    async function form_site_pay_add_finish(v) {
        v.site_id = sites.site_id.id;

        await addPay(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [addPay]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Payment added');
                hide_site_pay_add();
                await getSitesId({
                    id:params.siteID
                },dispatch,profile)
                .then(async json => {
                    console.log ( `%c [API]: [getSitesId]`, `color: #A366FF`, json );
                    if (json?.code) {
                        form_site_date_contract_data.resetFields();
        
                        form_site_date_contract_data.setFieldsValue({
                            id: json?.result?.id,
                            date_contract:moment.unix(json?.result?.date_contract).format("DD.MM.YYYY"),
                        });
        
                        getPayList({
                            site_id:params.siteID
                        },dispatch,profile)
                    } else {
                        let error = [];
                        if (json.error[0]) {
                            error.push(json.error[0]);
                        }
                        if (json.error[1]) {
                            error.push(json.error[1]);
                        }
                        if (json.error[3]) {
                            error.push((json.error[3]).join(', '));
                        }
                        message.error(error.join('. '));
                    }
                });
                getPayList({
                    site_id:params.siteID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function show_site_pay_data(pay) {
        console.log(pay);
        form_site_pay_data.resetFields();
        form_site_pay_data.setFieldsValue({
            id: pay.id,
            months: pay.months,
            status: pay.status
        });
        dispatch(updPanel('site_pay_data'));
    }

    async function hide_site_pay_data() {
        form_site_pay_data.resetFields();
        dispatch(updPanel('site_pay_data'));
    }

    async function form_site_pay_data_finish(v) {
        await updPay(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updPay]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Payment updated');
                hide_site_pay_data();
                await getSitesId({
                    id:params.siteID
                },dispatch,profile)
                .then(async json => {
                    console.log ( `%c [API]: [getSitesId]`, `color: #A366FF`, json );
                    if (json?.code) {
                        form_site_date_contract_data.resetFields();
        
                        form_site_date_contract_data.setFieldsValue({
                            id: json?.result?.id,
                            date_contract:moment.unix(json?.result?.date_contract).format("DD.MM.YYYY"),
                        });
        
                        getPayList({
                            site_id:params.siteID
                        },dispatch,profile)
                    } else {
                        let error = [];
                        if (json.error[0]) {
                            error.push(json.error[0]);
                        }
                        if (json.error[1]) {
                            error.push(json.error[1]);
                        }
                        if (json.error[3]) {
                            error.push((json.error[3]).join(', '));
                        }
                        message.error(error.join('. '));
                    }
                });
                getPayList({
                    site_id:params.siteID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function paysListExport() {
        const data = obj.pays.map((item, index) => {
            let container = {...item};
            container.months = `+${item.months} ${decnum(item.months,[ 'month','months','months' ])}`;
            container.date = `${moment.unix(sites.site_id.date_contract).add('months',obj.pays.slice(index,obj.pays.length).map(pay_item => pay_item.months).reduce((prev, curr) => +prev + +curr, 0)).format('DD/MM/YYYY')}`;
            delete container.id;
            delete container.status;
        
            return container;
        });

        const fileName = 'List of payments '+sites.site_id?.name+' ('+sites.site_id.domain+') '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            months: "Number of paid months",
            date: "Up to what date is paid",
        };

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Adding a payment" open={app_global.panel.indexOf( 'site_pay_add' ) != -1 } onOk={() => hide_site_pay_add()} onCancel={() => hide_site_pay_add()} footer={null}>
                <div className='header'>
                    <h2>Adding a payment</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_site_pay_add} onFinish={form_site_pay_add_finish} >
                        <label style={{marginTop:20}}>Number of months</label>
                        <Form.Item name={`months`} >
                            <InputNumber disabled={pays.loading} min={1} step={1} className='custom_input' placeholder="12" />
                        </Form.Item>
                        <div className='form_buttons'>
                            <Button className='submit' htmlType="submit" loading={pays.loading}>Save</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Change of payment" open={app_global.panel.indexOf( 'site_pay_data' ) != -1 } onOk={() => hide_site_pay_data()} onCancel={() => hide_site_pay_data()} footer={null}>
                <div className='header'>
                    <h2>Change of payment</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_site_pay_data} onFinish={form_site_pay_data_finish} >
                        <Form.Item hidden name={`id`} >
                            <Input disabled={pays.loading} className='custom_input' />
                        </Form.Item>
                        <label>Status</label>
                        <Form.Item name={`status`} >
                            <Select
                                disabled={pays.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Select status"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    [
                                        {
                                            label: 'Published',
                                            value: 'publish',
                                            color: '#52c41a'
                                        },
                                        {
                                            label: 'Cancelled',
                                            value: 'trash',
                                            color: '#f5222d'
                                        }
                                    ]
                                }
                            />
                        </Form.Item>
                        <label style={{marginTop:20}}>Number of months</label>
                        <Form.Item name={`months`} >
                            <InputNumber disabled={pays.loading} min={1} step={1} className='custom_input' placeholder="12" />
                        </Form.Item>
                        <div className='form_buttons'>
                            <Button className='submit' htmlType="submit" loading={pays.loading}>Save</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <section className='container --pays_item shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>List of site payments</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                {['3','4'].indexOf( profile?.data?.type ) !== -1 ?
                                    <div className='left_form'>
                                        {(!sites.loading && Object.keys(sites.site_id).length) ?
                                            <>
                                                <div className='default_form'>
                                                    <div className='form_buttons' style={{marginTop:0}}>
                                                        <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/pays`} ><span>Go back</span></Link>
                                                    </div>
                                                </div>
                                                <Form name="form" className="default_form" form={form_site_date_contract_data} onFinish={form_site_date_contract_upd_finish}>
                                                    <Form.Item hidden name={`id`} rules={[{ required: true, message: `Please enter the ID!` }]}>
                                                        <Input disabled={sites.loading} className='custom_input' placeholder={`ID`} />
                                                    </Form.Item>
                                                    <label>Date of conclusion of the transaction</label>
                                                    <Form.Item name={`date_contract`} >
                                                        <TimeInput
                                                            onChange={({ target: { value } }) => form_site_date_contract_data.setFieldsValue({
                                                                form_site_date_contract_data:value  
                                                            })}>
                                                        </TimeInput>
                                                    </Form.Item>
                                                    
                                                    <div className='form_buttons'>
                                                        <Button className='submit' style={{width:'100%'}} htmlType="submit" loading={sites.loading}>Save</Button>
                                                    </div>
                                                </Form>
                                                <div className="fcenter">
                                                    <Button className="submit_mini" icon={<PlusOutlined />} onClick={() => show_site_pay_add()} />
                                                </div>
                                                <List className='history_pays' itemLayout="vertical"
                                                    size="large"
                                                    pagination={{
                                                        pageSize: 5,
                                                        hideOnSinglePage: true,
                                                        showSizeChanger: false,
                                                        onChange(current, pageSize) {
                                                            setPage(current);
                                                            setPaginationSize(pageSize)
                                                        },
                                                        defaultCurrent: page
                                                    }}
                                                    locale={{
                                                        emptyText: 
                                                            <Empty 
                                                                description="The payment history is empty"
                                                            />
                                                    }}
                                                    dataSource={obj.pays}
                                                    renderItem={(item, index) => (
                                                        <List.Item style={{cursor:'pointer'}} key={index} onClick={() => show_site_pay_data(item)} >
                                                            <p>{`+${item.months} ${decnum(item.months,[ 'month','months','months' ])}`}<span>until {moment.unix(sites.site_id.date_contract).add('months',obj.pays.slice(index,obj.pays.length).map(pay_item => pay_item.months).reduce((prev, curr) => +prev + +curr, 0)).format('DD/MM/YYYY')}</span></p>
                                                        </List.Item>
                                                    )}
                                                />
                                                <div className='default_form'>
                                                    <div className='form_buttons' style={{marginTop:0}}>
                                                        <Button className='submit' style={{width:'100%'}} onClick={() => paysListExport()} loading={sites.loading}>Export to CSV</Button>
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            <Skeleton />
                                        }
                                    </div>
                                :
                                    null
                                }
                                <div className='right_content' style={{maxWidth:['3','4'].indexOf( profile?.data?.type ) !== -1 ? '':'100%'}}>
                                    {sites.loading ?
                                        <Skeleton active />
                                    :
                                        (!sites.loading && Object.keys(sites.site_id).length) ?
                                            <div className='info_data'>
                                                <div className='left'>
                                                    <div className={`spec_item_left ${sites.site_id.site_preview ? sites.site_id.site_preview : 'no_photo'}`}>
                                                        {sites.site_id.site_preview ? 
                                                            <img src={`${API}/uploads/${sites.site_id.site_preview}`} /> 
                                                        : 
                                                            <MdOutlineNoPhotography />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='right'>
                                                    <div className="org_name">
                                                        <span className="name">{sites.site_id?.name}</span>
                                                    </div>
                                                    <div className='org_info'>
                                                        <p className='category' style={{marginTop:8}}>
                                                            <span style={{backgroundColor:moment.unix(sites.site_id.date_contract).add('months', +sites.site_id.months).unix() > moment().unix() ? '#52c41a' : '#f5222d'}}><b>Briefly about payment: </b>{moment.unix(sites.site_id.date_contract).add('months', +sites.site_id.months).unix() > moment().unix() ? 'There is no debt' : 'There is a debt'}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Paid up to: </b>{moment.unix(sites.site_id.date_contract).add('months', +sites.site_id.months).format("DD/MM/YYYY")}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === sites.site_id?.country)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === sites.site_id?.engine)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of copyright protection: </b>{+sites.site_id?.copyright ? moment.unix(sites.site_id?.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                        </p>
                                                        <p className='category'><span style={{backgroundColor:obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.color}}>{obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.label}</span><span style={{cursor:'pointer'}} onClick={() => window.open(`https://${sites.site_id?.domain}/`,'_blank')}>{sites.site_id?.domain}</span></p>
                                                    </div>
                                                </div>
                                                <div className='bottom'>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>Description: </b>{sites.site_id?.excerpt}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>References to materials: </b>{sites.site_id?.material}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Site Features: </b>{sites.site_id?.features}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Contact details: </b>{sites.site_id?.contacts}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>SEO data: </b>{sites.site_id?.seo}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Where does the data from the feedback forms go: </b>{sites.site_id?.form}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of conclusion of the transaction: </b>{moment.unix(sites.site_id?.date_contract).format("DD/MM/YYYY")}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <Error404_Component />
                                        }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};