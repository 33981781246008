var users = [
    {
        "lastname": "Mukhina",
        "firstname": "Olga",
        "patronymic": "Ivanovna"
    },
    {
        "lastname": "Tretyakova",
        "firstname": "Kira",
        "patronymic": "Mikhailovna"
    },
    {
        "lastname": "Komarova",
        "firstname": "Aurora",
        "patronymic": "Ivanovna"
    },
    {
        "lastname": "Ivanova",
        "firstname": "Olesya",
        "patronymic": "Semenovna"
    },
    {
        "lastname": "Lavrova",
        "firstname": "Adelina",
        "patronymic": "Alexandrovna"
    },
    {
        "lastname": "Tarasov",
        "firstname": "Mikhail",
        "patronymic": "Ilyich"
    },
    {
        "lastname": "Golubev",
        "firstname": "Dmitry",
        "patronymic": "Alexandrovich"
    },
    {
        "lastname": "Kiselyov",
        "firstname": "Andrey",
        "patronymic": "Mikhailovich"
    },
    {
        "lastname": "Davydova",
        "firstname": "Alyona",
        "patronymic": "Ivanovna"
    },
    {
        "lastname": "Kuznetsov",
        "firstname": "Artem",
        "patronymic": "Leonidovich"
    },
    {
        "lastname": "Surkova",
        "firstname": "Alice",
        "patronymic": "Fedorovna"
    },
    {
        "lastname": "Fedorova",
        "firstname": "Alina",
        "patronymic": "Matveevna"
    },
    {
        "lastname": "Makarov",
        "firstname": "Maxim",
        "patronymic": "Alekseevich"
    },
    {
        "lastname": "Chernyaeva",
        "firstname": "Milana",
        "patronymic": "Matveevna"
    },
    {
        "lastname": "Denisov",
        "firstname": "Konstantin",
        "patronymic": "Denisovich"
    },
    {
        "lastname": "Sakharova",
        "firstname": "Veronika",
        "patronymic": "Glebovna"
    },
    {
        "lastname": "Zaitsev",
        "firstname": "Roman",
        "patronymic": "Matveyevich"
    },
    {
        "lastname": "Nesterov",
        "firstname": "Viktor",
        "patronymic": "Yanovich"
    },
    {
        "lastname": "Zaitseva",
        "firstname": "Maria",
        "patronymic": "Daniilovna"
    },
    {
        "lastname": "Ponomareva",
        "firstname": "Alice",
        "patronymic": "Damirovna"
    },
    {
        "lastname": "Denisov",
        "firstname": "Arseniy",
        "patronymic": "Pavlovich"
    },
    {
        "lastname": "Vasiliev",
        "firstname": "Alexey",
        "patronymic": "Yaroslavovich"
    },
    {
        "lastname": "Ovchinnikova",
        "firstname": "Zlata",
        "patronymic": "Ivanovna"
    },
    {
        "lastname": "Korneev",
        "firstname": "Matvey",
        "patronymic": "Kirillovich"
    },
    {
        "lastname": "Bogomolova",
        "firstname": "Ekaterina",
        "patronymic": "Nikitichna"
    },
    {
        "lastname": "Soboleva",
        "firstname": "Varvara",
        "patronymic": "Vadimovna"
    },
    {
        "lastname": "Ivanova",
        "firstname": "Vera",
        "patronymic": "Egorovna"
    },
    {
        "lastname": "Kozhevnikov",
        "firstname": "Ivan",
        "patronymic": "Alexandrovich"
    },
    {
        "lastname": "Baranov",
        "firstname": "Lev",
        "patronymic": "Bogdanovich"
    },
    {
        "lastname": "Solovyov",
        "firstname": "Mark",
        "patronymic": "Yaroslavovich"
    },
    {
        "lastname": "Levin",
        "firstname": "Vladislav",
        "patronymic": "Artemievich"
    },
    {
        "lastname": "Lebedev",
        "firstname": "Kirill",
        "patronymic": "Robertovich"
    },
    {
        "lastname": "Plotnikov",
        "firstname": "Miron",
        "patronymic": "Alexandrovich"
    },
    {
        "lastname": "Zaitseva",
        "firstname": "Sofia",
        "patronymic": "Konstantinovna"
    },
    {
        "lastname": "Muravyeva",
        "firstname": "Anastasia",
        "patronymic": "Yakovlevna"
    },
    {
        "lastname": "Khomyakov",
        "firstname": "Dmitry",
        "patronymic": "Markovich"
    },
    {
        "lastname": "Usov",
        "firstname": "Elisha",
        "patronymic": "Grigorievich"
    },
    {
        "lastname": "Trofimov",
        "firstname": "Maxim",
        "patronymic": "Lvovich"
    },
    {
        "lastname": "Merkulov",
        "firstname": "Mikhail",
        "patronymic": "Ilyich"
    },
    {
        "lastname": "Chernov",
        "firstname": "Mikhail",
        "patronymic": "Semenovich"
    },
    {
        "lastname": "Sokolova",
        "firstname": "Polina",
        "patronymic": "Pavlovna"
    },
    {
        "lastname": "Belousova",
        "firstname": "Eva",
        "patronymic": "Georgievna"
    },
    {
        "lastname": "Gorokhov",
        "firstname": "Alexey",
        "patronymic": "Markovich"
    },
    {
        "lastname": "Semenov",
        "firstname": "Alexander",
        "patronymic": "Alexandrovich"
    },
    {
        "lastname": "Morozova",
        "firstname": "Anna",
        "patronymic": "Matveevna"
    },
    {
        "lastname": "Kolesnikov",
        "firstname": "Matvey",
        "patronymic": "Viktorovich"
    },
    {
        "lastname": "Sevastyanov",
        "firstname": "Ivan",
        "patronymic": "Mikhailovich"
    },
    {
        "lastname": "Loginova",
        "firstname": "Elizabeth",
        "patronymic": "Ivanovna"
    },
    {
        "lastname": "Semenov",
        "firstname": "Daniel",
        "patronymic": "Matveyevich"
    },
    {
        "lastname": "Smirnova",
        "firstname": "Darina",
        "patronymic": "Daniilovna"
    }
];

export default users;