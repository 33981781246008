import {createSlice} from '@reduxjs/toolkit';

const checklistsSclice = createSlice({
    name: 'checklists',
    initialState: {
        loading: false,
        checklist_list: [],
        checklist_items: [],
        checklist_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setChecklist_list: (state, action) => {
            state.checklist_list = action.payload
        },
        setChecklist_items: (state, action) => {
            state.checklist_items = action.payload
        },
        setChecklist_id: (state, action) => {
            state.checklist_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setChecklist_list,
    setChecklist_items,
    setChecklist_id,
} = checklistsSclice.actions
export default checklistsSclice.reducer;