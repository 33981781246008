import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingPays, setPay_list_sites, setPay_list, setPay_id } from "../redux/pays/reducer";
import Request, { RequestFile } from "./handler.js";

export const getPayListSites = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingPays(true));
    data.method = 'pay_list_sites';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setPay_list_sites(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingPays(false));

    return result;
}

export const getPayList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingPays(true));
    data.method = 'pay_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setPay_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingPays(false));

    return result;
}

export const getPayId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingPays(true));
    data.method = 'pay_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setPay_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingPays(false));

    return result;
}

export const addPay = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingPays(true));
    data.method = 'pay_add';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingPays(false));

    return result;
}

export const updPay = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingPays(true));
    data.method = 'pay_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingPays(false));

    return result;
}