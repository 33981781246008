import {createSlice} from '@reduxjs/toolkit';

const checkSclice = createSlice({
    name: 'check',
    initialState: {
        loading: false,
        check_list: [],
        check_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setCheck_list: (state, action) => {
            state.check_list = action.payload
        },
        setCheck_id: (state, action) => {
            state.check_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setCheck_list,
    setCheck_id,
} = checkSclice.actions
export default checkSclice.reducer;