import React from 'react';
import { Helmet } from "react-helmet";
import './checklists_list.style';
import { ChecklistsList } from './checklists_list';

export class Checklists_Component extends React.Component {
    constructor( props ) {
        super();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{`Checklist list - Platform for checking sites R2`}</title>
                    <meta property='og:title' content={`Checklist list - Platform for checking sites R2`} />
                    <meta name="description" content={``} />
                    <meta name="og:description" content={``} />
                </Helmet>
                <ChecklistsList />
            </>
        );
    }
}