import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingError, setError_list, setError_id } from "../redux/errors/reducer";
import Request, { RequestFile } from "./handler.js";

export const getErrorList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingError(true));
    data.method = 'error_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setError_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingError(false));

    return result;
}

export const getErrorId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingError(true));
    data.method = 'error_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setError_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingError(false));

    return result;
}

export const updErrorExecutor = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingError(true));
    data.method = 'error_executor_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingError(false));

    return result;
}

export const updError = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingError(true));
    data.method = 'error_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingError(false));

    return result;
}