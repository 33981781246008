import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import { 
    getChecklistId,
    getChecklistItems,
    getChecklistList,
    addChecklist,
    addChecklistItem,
    updChecklist,
    updChecklistItem
} from '../../../API/checklists';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdFormatListNumbered } from 'react-icons/md';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';

export const ChecklistsList = props => {
    const dispatch = useDispatch();

    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const checklists = useSelector(state => state.checklists);

    const [checklist_list_search_name, setChecklist_list_search_name] = useState("");
    const [checklist_list_search_status, setChecklist_list_search_status] = useState("publish");

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_checklist_list_search] = Form.useForm();
    const [form_checklist_add] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    form_checklist_list_search.setFieldsValue({ 
        search: checklist_list_search_name, 
        status: checklist_list_search_status,
    });

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getChecklistList({

        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getChecklistList]`, `color: #A366FF`, json );
            if (json?.code) {
                //Done
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get checklist_list () {
            if ( checklists.checklist_list.length > 0 ) {
                let list = checklists.checklist_list;

                let search = checklist_list_search_name.toLowerCase();
                if (search !== "") {
                    list = checklists.checklist_list.filter( function( item ) {
                        return(
                            item['name'].toLowerCase().indexOf( search ) > -1 ||
                            item['excerpt'].toLowerCase().indexOf( search ) > -1
                        );
                    } );
                }

                if (checklist_list_search_status !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['status'] === checklist_list_search_status
                        );
                    } );
                }
                return list;
            } else {
                return checklists.checklist_list;
            }
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                 }
            ]
        },
        get times () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Weekly',
                    value: 'weekly',
                },
                {
                    label: 'Monthly',
                    value: 'monthly',
                }
            ]
        }
    };

    async function onChangeSearch() {
        var v = form_checklist_list_search.getFieldsValue();

        console.log(v);

        if (v['search'] !== undefined) {
            setChecklist_list_search_name(v['search'].replace(/\s+/g,' '));
        }

        if (v['status'] !== undefined) {
            setChecklist_list_search_status(v['status']);
        }
    }

    // Открытие модального окна создания сайта
    async function show_checklist_add() {
        form_checklist_add.resetFields();
        dispatch( updPanel('checklist_add') );
    }

    // Закрытие модального окна создания сайта
    async function hide_checklist_add() {
        dispatch( updPanel('checklist_add') );
    }

    // Отправка формы добавления сайта
    async function form_checklist_add_finish(v) {
        await addChecklist(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [addChecklist]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Checklist added');
                hide_checklist_add();
                getChecklistList({},dispatch,profile);
                window.open(`/checklists/${json?.result}`, "_blank");
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

    function getTimeSec(sec) {
        let out = '';

        let minutes = Math.floor(sec / 60);
        let seconds = +sec - (minutes * 60);

        if (minutes) {
            out += `${minutes} ${decnum(minutes,[ 'minute','minutes','minutes' ])} `;
        }

        if (seconds) {
            out += `${seconds} ${decnum(seconds,[ 'second','seconds','seconds' ])}`;
        }

        if (out == '') {
            out += `it is impossible to calculate the time`;
        }

        return out;
    };

    return (
        <>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Adding a checklist" open={app_global.panel.indexOf( 'checklist_add' ) != -1 } onOk={() => hide_checklist_add()} onCancel={() => hide_checklist_add()} footer={null}>
                <div className='header'>
                    <h2>Adding a checklist</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <div>
                        <Form name="form" className="default_form" form={form_checklist_add} onFinish={form_checklist_add_finish}>
                            <label>Title</label>
                            <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]}>
                                <Input disabled={checklists.loading} className='custom_input' placeholder={`Title`} />
                            </Form.Item>
                            <label>Description</label>
                            <Form.Item name={`excerpt`} >
                                <Input disabled={checklists.loading} className='custom_input' placeholder={`The main checklist for verification ...`} />
                            </Form.Item>
                            <label>Frequency of task creation</label>
                            <Form.Item name={`time`} rules={[{ required: true, message: `Please choose one of the options!` }]} >
                                <Select
                                    disabled={checklists.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Choose a time"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.times.filter((country_item) => ['','none'].indexOf( country_item.value ) === -1)}
                                />
                            </Form.Item>
                            
                            <div className='form_buttons'>
                                <Button className='submit' htmlType="submit" loading={checklists.loading}>Save</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
            <section className='container --checklists_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Checklist list</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                <div className='left_form'>
                                    <Form name="form" className="default_form" form={form_checklist_list_search}>
                                        <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                            <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/`} ><span>Go back</span></Link>
                                        </div>
                                        <Form.Item key="search" name="search">
                                            <Input disabled={checklists.loading} autoFocus className={`custom_input`} placeholder="Search by name" onChange={ () => onChangeSearch() } bordered={false} 
                                                prefix={
                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                }
                                                suffix={
                                                    ["4"].indexOf( profile?.data?.type ) !== -1 ?
                                                        <Button className='submit_mini' onClick={() => show_checklist_add()}>Add</Button>
                                                    :
                                                        false
                                                }
                                            />
                                        </Form.Item>
                                        <label>Status</label>
                                        <Form.Item name={`status`} >
                                            <Select
                                                disabled={checklists.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.status}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div className='right_content'>
                                    {checklists.loading ?
                                        <Skeleton active />
                                    :
                                        <List itemLayout="vertical"
                                            size="large"
                                            pagination={{
                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                                onChange(current, pageSize) {
                                                    setPage(current);
                                                    setPaginationSize(pageSize)
                                                },
                                                defaultCurrent: page
                                            }}
                                            locale={{
                                                emptyText: 
                                                    <Empty 
                                                        description="The checklist list is empty"
                                                    />
                                            }}
                                            dataSource={obj.checklist_list}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} >
                                                    <a target='_blank' href={`/checklists/${item.id}`} >
                                                        <div className='left'>
                                                            <div className={`spec_item_left no_photo`}>
                                                                <MdFormatListNumbered />
                                                            </div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className="org_name">
                                                                <span className="name">{item.name}</span>
                                                            </div>
                                                            <div className='org_bottom'>
                                                                <p className='last'>{item.excerpt}</p>
                                                            </div>
                                                            <div className='org_info'>
                                                                <p className='experience'>
                                                                    <span><b>Regularity: </b>{obj.times.find((find_item) => find_item.value === item.time)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Approximate check time: </b>{getTimeSec(item.full_time ?? 0)}</span>
                                                                </p>
                                                                <p className='category'><span style={{backgroundColor:obj.status.find((find_item) => find_item.value === item.status)?.color}}>{obj.status.find((find_item) => find_item.value === item.status)?.label}</span></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};