import React from 'react';
import { Helmet } from "react-helmet";
import './checklists_item.style';
import { ChecklistsItem } from './checklists_item';

export class Checklist_Component extends React.Component {
    constructor( props ) {
        super();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{`Viewing the checklist - Platform for checking sites R2`}</title>
                    <meta property='og:title' content={`Viewing the checklist - Platform for checking sites R2`} />
                    <meta name="description" content={``} />
                    <meta name="og:description" content={``} />
                </Helmet>
                <ChecklistsItem />
            </>
        );
    }
}