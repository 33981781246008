import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from './redux';

import { App } from './components/app';

const store = createStore(window.__PRELOADED_STATE__);
delete window.__PRELOADED_STATE__;

hydrate( <BrowserRouter><Provider store={store}><App/></Provider></BrowserRouter>, document.getElementById( 'app' ) );