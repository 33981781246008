import React from 'react';
import { Helmet } from "react-helmet";
import './error_list.style';
import { ErrorList } from './error_list';

export class Errors_Component extends React.Component {
    constructor( props ) {
        super();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{`Errors on websites - Platform for checking sites R2`}</title>
                    <meta property='og:title' content={`Errors on websites - Platform for checking sites R2`} />
                    <meta name="description" content={``} />
                    <meta name="og:description" content={``} />
                </Helmet>
                <ErrorList />
            </>
        );
    }
}