import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingSites, setSite_list, setSite_id } from "../redux/sites/reducer";
import Request, { RequestFile } from "./handler.js";


export const getSitesList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSites(true));
    data.method = 'sites_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setSite_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingSites(false));

    return result;
}

export const getSitesId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSites(true));
    data.method = 'sites_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setSite_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingSites(false));

    return result;
}

export const addSite = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSites(true));
    data.method = 'sites_add';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSites(false));

    return result;
}

export const updSite = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSites(true));
    data.method = 'sites_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSites(false));

    return result;
}

export const updSiteDateContract = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSites(true));
    data.method = 'sites_date_contract_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSites(false));

    return result;
}