import React, { useRef, useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Button, Input, Modal, Form, message, Skeleton, Progress, Radio, Select } from 'antd';
import { useCookies } from 'react-cookie';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import ReactCodeInput from "react-code-input";
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setActive_modal } from '../../redux/app_global/reducer';
import { setToken, setRegistration } from '../../redux/profile/reducer';
import { SETTINGS_APP } from "../../params";
import users from './users';
import {
    SearchOutlined,
    MenuOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    UserOutlined
} from '@ant-design/icons';
import { IoMdPlanet } from "react-icons/io";
import { TbMessageReport } from "react-icons/tb";
import { GoChecklist } from "react-icons/go";
import { MdErrorOutline, MdFormatListNumbered, MdPayment } from "react-icons/md";
import { BiTask, BiHelpCircle } from "react-icons/bi";

import { authorization, registration, forgot, getProfile, forgot_code, forgot_password } from '../../API/profile';

export const Header = props => {

    const dispatch = useDispatch();
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);

    const isInitialMount = useRef(true);
    const [activeMenu, setActiveMenu] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [activeTypeAuth, setActiveTypeAuth] = useState("email");
    const [activeTypeForgot, setActiveTypeForgot] = useState("email");

    const [form_authorization] = Form.useForm();
    const [form_registration] = Form.useForm();
    const [form_forgot] = Form.useForm();
    const [form_password] = Form.useForm();

    const [password_complexity, setPassword_complexity] = useState(0);
    const [tempForgotEmail, setTempForgotEmail] = useState("");
    const [tempForgotCode, setTempForgotCode] = useState("");
    const [tempAuthLogin, setTempAuthLogin] = useState("");
    const [tempAuthPassword, setTempAuthPassword] = useState("");

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            component_start();
        }
    }, []);

    useEffect(async () => {
        if (profile.token) {
            dispatch(setLoading(true));
            await getProfile({},dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [getProfile]`, `color: #A366FF`, json );
                if (json?.code) {
                    // Done
                } else {
                    message.error('The session is closed');
                    removeCookie('token', { path: '/' });
                    dispatch(setToken(''));
                }
                dispatch(setLoading(false));
            });
        }
    }, [profile.token]);

    async function component_start() {
        dispatch(setLoading(false));

        if (cookies.token) {
            dispatch(setToken(cookies.token));
        }
    }

    //* Авторизация
    async function showModalAuthorization() {
        setActiveMenu(false);

        form_authorization.resetFields();
        dispatch(setActive_modal('authorization'));
    }

    async function closeModalAuthorization() {
        dispatch(setActive_modal(''));
        await form_authorization.resetFields();
    }

    async function form_finish_authorization(v) {
        v['login'] = v['login'].replace(/ /ig, "").replace(/\+/ig, "");

        await authorization(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [authorization]`, `color: #A366FF`, json );
            if (json?.code) {
                if (json?.result.token === 'code') {
                    setTempAuthLogin(v['login']);
                    setTempAuthPassword(v['password']);
                    showModal2fa();
                } else {
                    message.success('You have logged in!');
                    setCookie('token', json?.result?.token, { path: '/' });
                    dispatch(setToken(json?.result?.token));
                    closeModalAuthorization();
                }
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    //* Авторизация
    async function showModal2fa() {
        dispatch(setActive_modal('2fa'));
    }

    async function closeModal2fa() {
        dispatch(setActive_modal(''));
    }

    async function form_finish_2fa(e) {
        if (e.length === 6) {
            await authorization({
                login:tempAuthLogin,
                password:tempAuthPassword,
                code:e
            },dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [authorization]`, `color: #A366FF`, json );
                if (json?.code) {
                    message.success('You have logged in!');
                    setCookie('token', json?.result?.token, { path: '/' });
                    dispatch(setToken(json?.result?.token));
                    closeModal2fa();
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }
    }

    //* Восстановление доступа
    async function showModalForgotCode() {
        dispatch(setActive_modal('forgotProfileCode'));
    }

    async function closeModalForgotCode() {
        dispatch(setActive_modal(''));
    }

    async function form_finish_forgot_code(e) {
        if (e.length === 6) {
            await forgot_code({
                login:tempForgotEmail,
                code:e
            },dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [forgot_code]`, `color: #A366FF`, json );
                if (json?.code) {
                    dispatch(setActive_modal('forgotProfilePassword'));
                    setTempForgotCode(e);
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }
    }

    //* Регистрация
    async function showModalRegistration() {
        form_registration.setFieldsValue({
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
        });
        dispatch(setActive_modal('registration'));
    }

    async function closeModalRegistration() {
        dispatch(setActive_modal(''));
        await form_registration.resetFields();
        await dispatch(setRegistration({}));
    }

    //* Регистрация
    async function form_finish_registration(v) {
        if (v['password'] !== v['password_repeat']) {
            message.error('Passwords don\'t match!');
            return true;
        }
        if (v['phone']) {
            v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        }
        await registration(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [registration]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('You have registered!');
                setCookie('token', json?.result?.token, { path: '/' });
                dispatch(setToken(json?.result?.token));
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    //* Восстановление пароля
    async function showModalForgot() {
        form_forgot.setFieldsValue({
            login: '',
            type: activeTypeForgot
        });
        dispatch(setActive_modal('forgot'));
    }

    async function closeModalForgot() {
        dispatch(setActive_modal(''));
        await form_forgot.resetFields();
    }

    async function form_finish_forgot(v) {
        v['login'] = v['login'].replace(/ /ig, "").replace(/\+/ig, "");

        await forgot(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [forgot]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('A code has been sent to you');
                setTempForgotEmail(v['login']);
                showModalForgotCode();
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    //* Изменение пароля
    async function closeModalForgotPassword() {
        dispatch(setActive_modal(''));
    }

    async function form_finish_profile_password(v) {
        if (checkPassword(v['password_new']) < 100) {
            message.error('The password is not complex enough');
            return false;
        }

        v['code'] = tempForgotCode;
        v['login'] = tempForgotEmail,
        await forgot_password(v,dispatch)
        .then(async json => {
            console.log ( `%c [API]: [forgot_password]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Password changed');
                showModalAuthorization();
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    const randomUser = users[Math.floor(Math.random() * (users.length - 0)) + 0];

    function checkPassword(e = '') {
        var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
        var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
        var digits = "0123456789"; // Цифры
        var specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы

        var is_s = false; // Есть ли в пароле буквы в нижнем регистре
        var is_b = false; // Есть ли в пароле буквы в верхнем регистре
        var is_d = false; // Есть ли в пароле цифры
        var is_sp = false; // Есть ли в пароле спецсимволы
                
        for (var i = 0; i < e.length; i++) {
            /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
            if (!is_s && s_letters.indexOf(e[i]) != -1) {
                is_s = true
            }
            else if (!is_b && b_letters.indexOf(e[i]) != -1) {
                is_b = true
            }
            else if (!is_d && digits.indexOf(e[i]) != -1) {
                is_d = true
            }
            else if (!is_sp && specials.indexOf(e[i]) != -1) {
                is_sp = true
            }
        }

        var rating = 0;
        if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
        if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
        if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
        if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
        /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */

        var score = 0;
        if (e.length < 6 && rating < 3) {
            score = 10;
        }
        if (e.length === 0) {
            score = 0;
        }
        else if (e.length < 6 && rating >= 3) {
            score = 50;
        }
        else if (e.length >= 8 && rating < 3) {
            score = 50;
        }
        else if (e.length >= 8 && rating >= 3) {
            score = 100;
        }
        else if (e.length >= 6 && rating == 1) {
            score = 10;
        }
        else if (e.length >= 6 && rating > 1 && rating < 4) {
            score = 50;
        }
        else if (e.length >= 6 && rating == 4) {
            score = 100;
        };

        setPassword_complexity(score);
        return score;
    }

    function onChangePassword(e) {
        checkPassword(e.target.value)
    }

    const links = [
        {
            name: 'Sites',
            link: '/sites',
            icon: IoMdPlanet,
            types: ['2','3','4']
        },
        {
            name: 'Reports',
            link: '/reports',
            icon: TbMessageReport,
            types: ['2','3','4']
        },
        {
            name: 'Check',
            link: '/reports/check',
            icon: GoChecklist,
            types: ['2','3','4']
        },
        {
            name: 'Errors',
            link: '/reports/errors',
            icon: MdErrorOutline,
            types: ['3','4']
        },
        {
            name: 'Tasks',
            link: '/tasks',
            icon: BiTask,
            types: ['2','3','4']
        },
        {
            name: 'Checklists',
            link: '/checklists',
            icon: MdFormatListNumbered,
            types: ['2','3','4']
        },
        {
            name: 'Pays',
            link: '/pays',
            icon: MdPayment,
            types: ['4']
        },
        {
            name: 'SOS',
            link: '/sos',
            icon: MdErrorOutline,
            types: ['2','3','4']
        },
        {
            name: 'Help',
            link: '/user_help',
            icon: BiHelpCircle,
            types: ['1']
        }
    ];

    return (
        <>
        <Modal mask centered className="modal_custom modal_authorization" key="m_forgot_password" width={700} title="Restoring access" open={app_global.active_modal === 'forgotProfilePassword'} onOk={() => closeModalForgotPassword()} onCancel={() => closeModalForgotPassword()} footer={null}>
                <div className='header'>
                    <h2>Restoring access</h2>
                    <h3>Enter a new password</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are logged in</h3>
                                </>
                            </div>
                        </>
                        :
                        <>
                            {profile.loading ?
                                <Skeleton.Button style={{marginBottom: '20px',width:260,height:50}} shape="round" active block={false} />
                            :
                            <Form name="form" className="form" form={form_password} onFinish={form_finish_profile_password} >
                                <label style={{marginTop:20}}>New password</label>
                                <Form.Item  name={`password_new`} rules={[{ required: true, message: `Please enter a new password!` }]}>
                                    <Input.Password onChange={(e) => onChangePassword(e)} disabled={profile.loading} className='custom_input' placeholder={`•••••••••`} />
                                </Form.Item>
                                <Progress percent={password_complexity} />
                                <div className='form_buttons'>
                                    <Button className='submit' htmlType="submit" loading={profile.loading}>Save</Button>
                                </div>
                            </Form>
                            }
                        </>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask centered className="modal_custom modal_authorization" key="m_forgot_code" width={700} title="Restoring access" open={app_global.active_modal === 'forgotProfileCode'} onOk={() => closeModalForgotCode()} onCancel={() => closeModalForgotCode()} footer={null}>
                <div className='header'>
                    <h2>Restoring access</h2>
                    <h3>Enter the code</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are logged in</h3>
                                </>
                            </div>
                        </>
                        :
                        <div className="block_pin">
                            {profile.loading ?
                                <Skeleton.Button style={{marginBottom: '20px',width:260,height:50}} shape="round" active block={false} />
                            :
                                <ReactCodeInput
                                    type="number"
                                    fields={6}
                                    onChange={(e) => form_finish_forgot_code(e)}
                                />
                            }
                        </div>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask centered className="modal_custom modal_authorization" key="m_authorization" width={700} title="Authorization" open={app_global.active_modal === 'authorization'} onOk={() => closeModalAuthorization()} onCancel={() => closeModalAuthorization()} footer={null}>
                <div className='header'>
                    <h2>Authorization</h2>
                    <h3>in the platform for testing sites R2</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are already registered</h3>
                                </>
                            </div>
                        </>
                        :
                        <>
                            <Form name="form" className="form" form={form_authorization} onFinish={form_finish_authorization}>
                                <Radio.Group style={{display:'none'}} className='radio_login' onChange={(e) => {setActiveTypeAuth(e.target.value); form_authorization.setFieldsValue({login:''})}} defaultValue="email">
                                    <Radio.Button value="phone">Login by phone</Radio.Button>
                                    <Radio.Button value="email">Login by mail</Radio.Button>
                                </Radio.Group>
                                <label>E-mail</label>
                                <Form.Item name={`login`} rules={[{ required: true, message: `Please enter email!` }]}>
                                    <MaskedInput
                                        disabled={app_global.loading}
                                        mask={emailMask}
                                        placeholder="mail@mail.ru"
                                        render={(ref, props) => (
                                            <input className="ant-input custom_input" type="text" aria-required="true" ref={ref} {...props} />
                                        )}
                                    />
                                </Form.Item>
                                <label>Password</label>
                                <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]} >
                                    <Input.Password disabled={app_global.loading} className='custom_input' type="password" placeholder="••••••••••" iconRender={visible => (visible ? <EyeOutlined style={{color:'#9B9B9B'}} /> : <EyeInvisibleOutlined style={{color:'#9B9B9B'}} />)} />
                                </Form.Item>
                                <span className='forgot_password' onClick={() => showModalForgot()}>Forgot your password?</span>
                                <div className='form_buttons'>
                                    <Button className='button_left' onClick={() => showModalRegistration()}>Create a new account</Button>
                                    <Button className='submit' htmlType="submit" loading={app_global.loading}>Log in</Button>
                                </div>
                            </Form>
                        </>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask centered className="modal_custom modal_authorization" key="m_auth_code" width={700} title="2FA" open={app_global.active_modal === '2fa'} onOk={() => closeModal2fa()} onCancel={() => closeModal2fa()} footer={null}>
                <div className='header'>
                    <h2>Authorization</h2>
                    <h3>Enter the code</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are logged in</h3>
                                </>
                            </div>
                        </>
                        :
                        <div className="block_pin">
                            {profile.loading ?
                                <Skeleton.Button style={{marginBottom: '20px',width:260,height:50}} shape="round" active block={false} />
                            :
                                <ReactCodeInput
                                    type="number"
                                    fields={6}
                                    onChange={(e) => form_finish_2fa(e)}
                                />
                            }
                        </div>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask centered className="modal_custom modal_authorization" key="m_registration" width={700} title="Регистрация" open={app_global.active_modal === 'registration'} onOk={() => closeModalRegistration()} onCancel={() => closeModalRegistration()} footer={null}>
                <div className='header'>
                    <h2>Registration</h2>
                    <h3>in the platform for testing sites R2</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            {
                                Object.keys(profile?.registration).length > 0 ?
                                    <div className='result'>
                                        <h3>Successful registration!</h3>
                                        <p className='text'>Your password:</p>
                                        <p className='password'>{profile?.registration?.password}</p>
                                    </div>
                            :
                                <div className='result'>
                                    <>
                                        <h3>You are already registered</h3>
                                    </>
                                </div>
                            }
                        </>
                        :
                        <>
                            {
                                Object.keys(profile?.registration).length > 0 ?
                                    <div className='result'>
                                        <h3>Successful registration!</h3>
                                        <p className='text'>Your password:</p>
                                        <p className='password'>{profile?.registration?.password}</p>
                                    </div>
                                :
                                <Form name="form" className="form default_form" form={form_registration} onFinish={form_finish_registration}>
                                    <label>First name</label>
                                    <Form.Item name={`firstname`} rules={[{ required: true, message: `Please enter your name!` }]}>
                                        <Input disabled={app_global.loading} className='custom_input' placeholder={randomUser['firstname']} />
                                    </Form.Item>
                                    <label>Last name</label>
                                    <Form.Item name={`lastname`} >
                                        <Input disabled={app_global.loading} className='custom_input' placeholder={randomUser['lastname']} />
                                    </Form.Item>
                                    <label>Gender</label>
                                    <Form.Item name={`gender`} rules={[{ required: true, message: `Please specify the gender!` }]}>
                                        <Select
                                            disabled={app_global.loading}
                                            className="custom_select"
                                            showSearch
                                            placeholder="Выбрать пол"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={[
                                                {value:"1",label:"Male"},
                                                {value:"2",label:"Female"}
                                            ]}
                                        />
                                    </Form.Item>
                                    <label>E-mail</label>
                                    <Form.Item name={`email`} rules={[{ required: true, message: `Please enter email!` }]} >
                                        <MaskedInput
                                            disabled={app_global.loading}
                                            mask={emailMask}
                                            placeholder="mail@mail.ru"
                                            render={(ref, props) => (
                                                <input className="ant-input custom_input" type="text" aria-required="true" ref={ref} {...props} />
                                            )}
                                        />
                                    </Form.Item>
                                    
                                    <span className='forgot_password' onClick={() => showModalForgot()}>Forgot your password?</span>
                                    <div className='form_buttons'>
                                        <Button className='button_left' onClick={() => showModalAuthorization()}>I have an account</Button>
                                        <Button className='submit' htmlType="submit" loading={app_global.loading}>To create</Button>
                                    </div>
                                </Form>
                            }
                        </>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask centered className="modal_custom modal_authorization" key="m_forgot" width={700} title="Restoring access" open={app_global.active_modal === 'forgot'} onOk={() => closeModalForgot()} onCancel={() => closeModalForgot()} footer={null}>
                <div className='header'>
                    <h2>Restoring access</h2>
                    <h3>in the platform for testing sites R2</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are already registered</h3>
                                </>
                            </div>
                        </>
                        :
                        <>
                            <Form name="form" className="form" form={form_forgot} onFinish={form_finish_forgot}>
                                <Radio.Group style={{display:'none'}} className='radio_login' onChange={(e) => {setActiveTypeForgot(e.target.value); form_forgot.setFieldsValue({login:'',type:e.target.value})}} >
                                    <Radio.Button value="phone">By phone</Radio.Button>
                                    <Radio.Button value="email">By mail</Radio.Button>
                                </Radio.Group>
                                <Form.Item hidden name={`type`} rules={[{ required: true, message: `Please enter the type!` }]}>
                                    <Input disabled={app_global.loading} className='custom_input' defaultValue={`phone`} />
                                </Form.Item>
                                {activeTypeForgot === 'phone' ? 
                                    <>
                                    <label>Phone</label>
                                    <Form.Item name={`login`} rules={[{ required: true, message: `Please enter your phone number!` }]}>
                                        <MaskedInput
                                            disabled={app_global.loading}
                                            mask={SETTINGS_APP.mask[SETTINGS_APP.project_format]['account']}
                                            placeholder={SETTINGS_APP.mask[SETTINGS_APP.project_format]['placeholder']}
                                            render={(ref, props) => (
                                                <input className="ant-input custom_input" type="text" aria-required="true" ref={ref} {...props} />
                                            )}
                                        />
                                    </Form.Item>
                                    </>
                                :
                                    <>
                                        <label>E-mail</label>
                                        <Form.Item name={`login`} rules={[{ required: true, message: `Please enter email!` }]}>
                                            <MaskedInput
                                                disabled={app_global.loading}
                                                mask={emailMask}
                                                placeholder="mail@mail.ru"
                                                render={(ref, props) => (
                                                    <input className="ant-input custom_input" type="text" aria-required="true" ref={ref} {...props} />
                                                )}
                                            />
                                        </Form.Item>
                                    </>
                                }
                                <div className='form_buttons'>
                                    <Button className='button_left' onClick={() => showModalAuthorization()}>Go back to the login form</Button>
                                    <Button className='submit' htmlType="submit" loading={app_global.loading}>Continue</Button>
                                </div>
                            </Form>
                        </>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <header className={activeMenu ? 'active':null}>
                <div className='container --header'>

                    <div className='content'>
                        <Button className='menu' type="text" shape="circle" onClick={() => setActiveMenu(!activeMenu)} icon={
                            activeMenu ? 
                            <CloseOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                            :
                            <MenuOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                        }></Button>
                        <Link to='/' exact={ true } className='icon__mp' >
                            <span>R2</span> Dev
                        </Link>
                        <span className='mobile_visible'>
                            <span className='a search'>
                                {
                                    profile.auth ?
                                    <Link to='/profile' exact={ true } className='ant-btn ant-btn-circle ant-btn-primary ant-btn-lg' ><span><UserOutlined style={{ color: '#fff' }} /></span></Link>
                                    :
                                    <Button onClick={() => showModalAuthorization()} type="primary" shape="circle" size='large' ><UserOutlined style={{ color: '#fff' }} /></Button>
                                }
                            </span>
                        </span>
                        <div className='mobile_hidden button_auth' >
                            {
                                profile.auth ?
                                <Link to='/profile' exact={ true } loading={app_global.loading} className='ant-btn ant-btn-primary' ><span>Profile</span></Link>
                                :
                                <Button loading={app_global.loading} type="primary" className='' onClick={() => showModalAuthorization()} >Log in</Button>
                            }
                        </div>
                    </div>
                </div>
                <div className='menu_mobile'>
                    <div className='container'>
                        <div className='content'>
                            <div className='head'>
                                <div className='head_container'>
                                    <Button className='menu' type="text" shape="circle" onClick={() => setActiveMenu(!activeMenu)} icon={
                                        activeMenu ? 
                                        <CloseOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                        :
                                        <MenuOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                    }></Button>
                                    <Link to='/' exact={ true } className='icon__mp' onClick={() => setActiveMenu(false)} >
                                        <span>R2</span> Dev
                                    </Link>
                                </div>
                            </div>
                            <ul className='menu_mobile_list'>
                                {
                                    profile.auth ?
                                    <Link className="button_profile" to='/profile' exact={ true } onClick={() => setActiveMenu(false)} >
                                        <span>Profile</span>
                                    </Link>
                                    :
                                    <li className="button_profile" onClick={ () => showModalAuthorization() }>
                                        <span>Log in</span>
                                    </li>
                                }
                                <Link to='/' exact={ true } onClick={() => setActiveMenu(false)} >
                                    <span>Home</span>
                                </Link>
                                {profile.auth ?
                                   links.filter((item) => item.types.indexOf( profile?.data?.type ) !== -1).map((item, index) => {
                                        return (
                                            <Link key={index} to={item.link} exact={ true } onClick={() => setActiveMenu(false)} ><span>{item.name}</span></Link>
                                        );
                                    })
                                :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='mask-container' onClick={() => setActiveMenu(!activeMenu)}>

                    </div>
                </div>
            </header>
        </>
    );
};