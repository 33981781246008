import React from 'react';

export const Footer = () => {

    return (
        <footer>
            
        </footer>
    );
};

