import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingChecklists, setChecklist_list, setChecklist_items, setChecklist_id } from "../redux/checklists/reducer";
import Request, { RequestFile } from "./handler.js";

export const getChecklistList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setChecklist_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const getChecklistItems = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_items';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setChecklist_items(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const getChecklistId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setChecklist_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const addChecklist = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_add';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const addChecklistItem = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_item_add';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const updChecklist = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}

export const updChecklistItem = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingChecklists(true));
    data.method = 'checklist_item_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingChecklists(false));

    return result;
}