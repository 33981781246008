import {createSlice} from '@reduxjs/toolkit';

const appSclice = createSlice({
    name: 'app_global',
    initialState: {
        loading: true,
        loading_modal: false,
        panel: [],
        active_modal: '',
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        updPanel: (state, action) => {
            var panels = state.panel;
            var t = action.payload;
            console.log(t,panels);
            if (panels.indexOf( t ) != -1) {
                var index = panels.indexOf(t);
                if (index > -1) panels.splice(index, 1);
            } else {
                panels.push(t);
            }
            state.panel = panels
        },
        setLoading_modal: (state, action) => {
            state.loading_modal = action.payload
        },
        setActive_modal: (state, action) => {
            state.active_modal = action.payload
        },
    },
});

export const { 
    setLoading,
    updPanel,
    setLoading_modal,
    setActive_modal
} = appSclice.actions
export default appSclice.reducer;