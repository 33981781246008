import React from 'react';
import { Helmet } from "react-helmet";
import './error_item.style';
import { ErrorItem } from './error_item';

export class Error_Component extends React.Component {
    constructor( props ) {
        super();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{`Viewing an error - Platform for checking sites R2`}</title>
                    <meta property='og:title' content={`Viewing an error - Platform for checking sites R2`} />
                    <meta name="description" content={``} />
                    <meta name="og:description" content={``} />
                </Helmet>
                <ErrorItem />
            </>
        );
    }
}