import { setLoading } from "../redux/app_global/reducer";
import { setLoading as setLoadingSos, setSos_list, setSos_id } from "../redux/sos/reducer";
import Request, { RequestFile } from "./handler.js";

export const addSos = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSos(true));
    data.append('method', 'sos_add');

    if (profile.token) {
        data.append('token', profile.token);
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSos(false));

    return result;
}

export const getSosList = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSos(true));
    data.method = 'sos_list';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setSos_list(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingSos(false));

    return result;
}

export const getSosId = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSos(true));
    data.method = 'sos_id';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {
            await dispatch(setSos_id(json.result));
        }
        
        result = json;
    });
    await dispatch(setLoadingSos(false));

    return result;
}

export const updSosExecutor = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSos(true));
    data.method = 'sos_executor_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSos(false));

    return result;
}

export const updSos = async (data,dispatch,profile) => {
    var result = {};
    
    await dispatch(setLoadingSos(true));
    data.method = 'sos_upd';

    if (profile.token) {
        data.token = profile.token;
    }
    
    await Request(data, 'post')
    .then(async json => {

        if (json?.code) {

        }
        
        result = json;
    });
    await dispatch(setLoadingSos(false));

    return result;
}