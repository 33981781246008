import { combineReducers } from '@reduxjs/toolkit';

import appReducer from './app_global/reducer';
import profileReducer from './profile/reducer';
import sitesSclice from './sites/reducer';
import paysSclice from './pays/reducer';
import checklistsSclice from './checklists/reducer';
import checkSclice from './check/reducer';
import errorSclice from './errors/reducer';
import sosSclice from './sos/reducer';

const rootReducer = combineReducers({
    app_global: appReducer,
    profile: profileReducer,
    sites: sitesSclice,
    pays: paysSclice,
    checklists: checklistsSclice,
    check: checkSclice,
    errors: errorSclice,
    sos: sosSclice
});

export default rootReducer;