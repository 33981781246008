import React, { useRef, useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { Skeleton, message, Radio, Form, InputNumber, Button, Modal, Select, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IMAGES} from '../../params';
import { UPLOADS } from '../../params';
import { useCookies } from 'react-cookie';
import { API } from '../../params';
import { SETTINGS_APP } from "../../params";
import { setActive_modal } from "../../redux/app_global/reducer";
import { Error403_Component } from '../errors/403';
import { IoMdPlanet } from "react-icons/io";
import { TbMessageReport } from "react-icons/tb";
import { GoChecklist } from "react-icons/go";
import { MdErrorOutline, MdFormatListNumbered, MdPayment } from "react-icons/md";
import { BiTask, BiHelpCircle } from "react-icons/bi";


export const Reports = props => {
    const dispatch = useDispatch();

    const isInitialMount = useRef(true);
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            component_start();
        }
    }, []);

    async function component_start() {

    }

    const links = [
        {
            name: 'Check',
            link: '/reports/check',
            icon: GoChecklist,
            types: ['2','3','4']
        },
        {
            name: 'Errors',
            link: '/reports/errors',
            icon: MdErrorOutline,
            types: ['3','4']
        },
    ];

    return (
        <>
            <div style={{background:'#fff',height:'100%',display:'flex',flexDirection:'column'}}>
                <section className='container --reports -first'>
                    <div className='content'>
                        {['2','3','4'].indexOf( profile?.data?.type ) !== -1 ?
                            <>
                                <h1>Reports</h1>
                                <>
                                    <div className='links'>
                                        {links.filter((item) => item.types.indexOf( profile?.data?.type ) !== -1).map((item, index) => {
                                            const Icon = item.icon;
                                            return (
                                                <Link key={index} exact={ true } to={item.link}><Icon /><span>{item.name}</span></Link>
                                            );
                                        })}
                                    </div>       
                                </>
                            </>
                        :
                            <Error403_Component />
                        }
                    </div>
                </section>
            </div>
        </>
    );
};