import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, InputNumber, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import exportFromJSON from 'export-from-json'
import { 
    getChecklistId,
    getChecklistItems,
    getChecklistList,
    addChecklist,
    addChecklistItem,
    updChecklist,
    updChecklistItem
} from '../../../API/checklists';
import {
    PlusOutlined
} from '@ant-design/icons';
import { MdOutlineNoPhotography } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';
import { Error404_Component } from '../../errors/404';

const { TextArea } = Input;

export const ChecklistsItem = props => {
    const dispatch = useDispatch();

    const params = useParams();
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const checklists = useSelector(state => state.checklists);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_checklist_data] = Form.useForm();
    const [form_checklist_item_add] = Form.useForm();
    const [form_checklist_item_data] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getChecklistId({
            id:params.checklistID
        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getChecklistId]`, `color: #A366FF`, json );
            if (json?.code) {
                form_checklist_data.resetFields();

                form_checklist_data.setFieldsValue({
                    id: json?.result?.id,
                    name: json?.result?.name,
                    excerpt: json?.result?.excerpt,
                    time: json?.result?.time,
                    status: json?.result?.status,
                });

                getChecklistItems({
                    checklist_id:params.checklistID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get items () {
            return checklists.checklist_items;
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                }
            ]
        },
        get times () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Weekly',
                    value: 'weekly',
                },
                {
                    label: 'Monthly',
                    value: 'monthly',
                }
            ]
        },
        get importance () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Weak',
                    value: '1',
                },
                {
                    label: 'Average',
                    value: '2',
                },
                {
                    label: 'High',
                    value: '3',
                },
            ]
        },
        get notif () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                },
                {
                    label: 'Yes',
                    value: '1',
                },
                {
                    label: 'No',
                    value: '0',
                }
            ]
        }
    };

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }
    

    // Отправка формы обновления сайта
    async function form_checklist_item_data_finish(v) {
        await updChecklist(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updChecklist]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The checklist has been updated');
                getChecklistId({id:v.id},dispatch,profile);
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    function TimeInput(props) {
        let mask = 'dD.mM.YYYY';
        let formatChars = {
            'Y': '[0-9]',
            'd': '[0-3]',
            'D': '[0-9]',
            'm': '[0-1]',
            'M': '[1-9]'
        };
      
        let beforeMaskedValueChange = (newState, oldState, userInput) => {
            let { value } = newState;
        
            let dateParts = value.split('.');
            let dayPart = dateParts[0];
            let monthPart = dateParts[1];
        
            // Conditional mask for the 2nd digit of day based on the first digit
            if(dayPart.startsWith('3'))
                formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
            else if(dayPart.startsWith('0'))
                formatChars['D'] = '[1-9]'; // To block 00.
            else
                formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.
        
        
            // Conditional mask for the 2nd digit of month based on the first digit
            if(monthPart.startsWith('1'))
                formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
            else
                formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.
        
            return {value, selection: newState.selection};
        }
        return (
            <InputMask
                className='ant-input custom_input'
                mask={mask}
                value={props.value}
                onChange={props.onChange}
                formatChars={formatChars}
                beforeMaskedValueChange={beforeMaskedValueChange}>
            </InputMask>
        );
    }

    async function show_checklist_item_add() {
        form_checklist_item_add.resetFields();
        dispatch(updPanel('checklist_item_add'));
    }

    async function hide_checklist_item_add() {
        form_checklist_item_add.resetFields();
        dispatch(updPanel('checklist_item_add'));
    }

    async function form_checklist_item_add_finish(v) {
        v.checklist_id = checklists.checklist_id.id;

        await addChecklistItem(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [addChecklistItem]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Item added');
                hide_checklist_item_add();
                getChecklistId({
                    id:params.checklistID
                },dispatch,profile);
                getChecklistItems({
                    checklist_id:params.checklistID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function show_checklist_item_data(item) {
        console.log(item);
        form_checklist_item_data.resetFields();
        form_checklist_item_data.setFieldsValue({
            id: item.id,
            name: item.name,
            excerpt: item.excerpt,
            time: item.time,
            importance: item.importance,
            notif: item.notif,
            status: item.status
        });
        dispatch(updPanel('checklist_item_data'));
    }

    async function hide_checklist_item_data() {
        form_checklist_item_data.resetFields();
        dispatch(updPanel('checklist_item_data'));
    }

    async function form_checklist_item_data_finish(v) {
        await updChecklistItem(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updChecklistItem]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Item updated');
                hide_checklist_item_data();
                getChecklistId({
                    id:params.checklistID
                },dispatch,profile);
                getChecklistItems({
                    checklist_id:params.checklistID
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function form_checklist_data_finish(v) {
        await updChecklist(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updChecklist]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The checklist has been updated');
                getChecklistId({id:v.id},dispatch,profile);
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    function getTimeSec(sec) {
        let out = '';

        let minutes = Math.floor(sec / 60);
        let seconds = +sec - (minutes * 60);

        if (minutes) {
            out += `${minutes} ${decnum(minutes,[ 'minute','minutes','minutes' ])} `;
        }

        if (seconds) {
            out += `${seconds} ${decnum(seconds,[ 'second','seconds','seconds' ])}`;
        }

        if (out == '') {
            out += `it is impossible to calculate the time`;
        }

        return out;
    };

    async function checklistExport() {
        let data = obj.items.map((item) => {
            let container = {...item};
            container.importance = obj.importance.find((find_item) => find_item.value === item.importance)?.label;
            container.notif = obj.notif.find((find_item) => find_item.value === item.notif)?.label;
            container.time = getTimeSec(item.time ?? 0)
            delete container.id;
            delete container.checklist_id;
            delete container.status;
        
            return container;
        });

        data.unshift({
            name: "",
            excerpt: "",
            importance: "",
            notif: "",
            time: ""
        });

        data.unshift({
            name: "Time spent",
            excerpt: getTimeSec(checklists.checklist_id.full_time ?? 0),
            importance: "",
            notif: "",
            time: ""
        });

        data.unshift({
            name: "Time",
            excerpt: obj.times.find((find_item) => find_item.value === checklists.checklist_id.time)?.label,
            importance: "",
            notif: "",
            time: ""
        });

        data.unshift({
            name: "Description",
            excerpt: checklists.checklist_id.excerpt,
            importance: "",
            notif: "",
            time: ""
        });

        data.unshift({
            name: "Checklist",
            excerpt: checklists.checklist_id.name,
            importance: "",
            notif: "",
            time: ""
        });

        const fileName = 'List of checklist items '+checklists.checklist_id.name+' '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            name: "Item name",
            excerpt: "Item Description",
            importance: "Importance",
            notif: "Notify in the telegram channel",
            time: "Time spent"
        };

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Adding an item" open={app_global.panel.indexOf( 'checklist_item_add' ) != -1 } onOk={() => hide_checklist_item_add()} onCancel={() => hide_checklist_item_add()} footer={null}>
                <div className='header'>
                    <h2>Adding an item</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_checklist_item_add} onFinish={form_checklist_item_add_finish} >
                        <label style={{marginTop:20}}>Title</label>
                        <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]} >
                            <Input disabled={checklists.loading} className='custom_input' placeholder="Title" />
                        </Form.Item>
                        <label>Description</label>
                        <Form.Item name={`excerpt`} >
                            <Input disabled={checklists.loading} className='custom_input' placeholder="Description" />
                        </Form.Item>
                        <label>Time spent</label>
                        <Form.Item name={`time`} rules={[{ required: true, message: `Please specify the time!` }]} >
                            <InputNumber disabled={checklists.loading} min={1} step={1} className='custom_input' placeholder="5" />
                        </Form.Item>
                        <label>Importance</label>
                        <Form.Item name={`importance`} rules={[{ required: true, message: `Please choose the importance!` }]} >
                            <Select
                                disabled={checklists.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choosing Importance"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={obj.importance.filter((importance_item) => ['','none'].indexOf( importance_item.value ) === -1)}
                            />
                        </Form.Item>
                        <label>Notify in the telegram channel</label>
                        <Form.Item name={`notif`} rules={[{ required: true, message: `Please select an option!` }]} >
                            <Select
                                disabled={checklists.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choosing an option"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={obj.notif.filter((importance_item) => ['','none'].indexOf( importance_item.value ) === -1)}
                            />
                        </Form.Item>
                        <div className='form_buttons'>
                            <Button className='submit' htmlType="submit" loading={checklists.loading}>Save</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Changing the item" open={app_global.panel.indexOf( 'checklist_item_data' ) != -1 } onOk={() => hide_checklist_item_data()} onCancel={() => hide_checklist_item_data()} footer={null}>
                <div className='header'>
                    <h2>Changing the item</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_checklist_item_data} onFinish={form_checklist_item_data_finish} >
                        <Form.Item hidden name={`id`} >
                            <Input disabled={checklists.loading} className='custom_input' />
                        </Form.Item>
                        <label>Status</label>
                        <Form.Item name={`status`} >
                            <Select
                                disabled={checklists.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Select status"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    [
                                        {
                                            label: 'Published',
                                            value: 'publish',
                                            color: '#52c41a'
                                        },
                                        {
                                            label: 'Cancelled',
                                            value: 'trash',
                                            color: '#f5222d'
                                        }
                                    ]
                                }
                            />
                        </Form.Item>
                        <label>Title</label>
                        <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]} >
                            <Input disabled={checklists.loading} className='custom_input' placeholder="Title" />
                        </Form.Item>
                        <label>Description</label>
                        <Form.Item name={`excerpt`} >
                            <Input disabled={checklists.loading} className='custom_input' placeholder="Description" />
                        </Form.Item>
                        <label>Time spent</label>
                        <Form.Item name={`time`} rules={[{ required: true, message: `Please specify the time!` }]} >
                            <InputNumber disabled={checklists.loading} min={1} step={1} className='custom_input' placeholder="5" />
                        </Form.Item>
                        <label>Importance</label>
                        <Form.Item name={`importance`} rules={[{ required: true, message: `Please choose the importance!` }]} >
                            <Select
                                disabled={checklists.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choosing Importance"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={obj.importance.filter((importance_item) => ['','none'].indexOf( importance_item.value ) === -1)}
                            />
                        </Form.Item>
                        <label>Notify in the telegram channel</label>
                        <Form.Item name={`notif`} rules={[{ required: true, message: `Please select an option!` }]} >
                            <Select
                                disabled={checklists.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choosing an option"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={obj.notif.filter((importance_item) => ['','none'].indexOf( importance_item.value ) === -1)}
                            />
                        </Form.Item>
                        <div className='form_buttons'>
                            <Button className='submit' htmlType="submit" loading={checklists.loading}>Save</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <section className='container --checklists_item shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Viewing the checklist</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                {['4'].indexOf( profile?.data?.type ) !== -1 ?
                                    <div className='left_form'>
                                        {(!checklists.loading && Object.keys(checklists.checklist_id).length) ?
                                            <Form name="form" className="default_form" form={form_checklist_data} onFinish={form_checklist_data_finish}>
                                                <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                                    <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/checklists`} ><span>Go back</span></Link>
                                                </div>
                                                <Form.Item hidden name={`id`} rules={[{ required: true, message: `Please enter the ID!` }]}>
                                                    <Input disabled={checklists.loading} className='custom_input' placeholder={`ID`} />
                                                </Form.Item>
                                                <label>Status</label>
                                                <Form.Item name={`status`} rules={[{ required: true, message: `Please select the status!` }]} >
                                                    <Select
                                                        disabled={checklists.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Select status"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.status.filter((status_item) => ['','none'].indexOf( status_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                <label>Title</label>
                                                <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]}>
                                                    <Input disabled={checklists.loading} className='custom_input' placeholder={`Title`} />
                                                </Form.Item>
                                                <label>Description</label>
                                                <Form.Item name={`excerpt`} >
                                                    <Input disabled={checklists.loading} className='custom_input' placeholder={`The main checklist for verification ...`} />
                                                </Form.Item>
                                                <label>Frequency of task creation</label>
                                                <Form.Item name={`time`} rules={[{ required: true, message: `Please choose one of the options!` }]} >
                                                    <Select
                                                        disabled={checklists.loading}
                                                        className="custom_select"
                                                        showSearch
                                                        placeholder="Choose a time"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={obj.times.filter((country_item) => ['','none'].indexOf( country_item.value ) === -1)}
                                                    />
                                                </Form.Item>
                                                
                                                <div className='form_buttons'>
                                                    <Button className='submit' style={{width:'100%'}} htmlType="submit" loading={checklists.loading}>Save</Button>
                                                </div>
                                                <div className='form_buttons'>
                                                    <Button className='submit' style={{width:'100%'}} onClick={() => checklistExport()} loading={checklists.loading}>Export to CSV</Button>
                                                </div>
                                            </Form>
                                        :
                                            <Skeleton />
                                        }
                                    </div>
                                :
                                    null
                                }
                                <div className='right_content' style={{maxWidth:['4'].indexOf( profile?.data?.type ) !== -1 ? '':'100%'}}>
                                    {checklists.loading ?
                                        <Skeleton active />
                                    :
                                        (!checklists.loading && Object.keys(checklists.checklist_id).length) ?
                                            <>
                                                <div className="fcenter">
                                                    <Button className="submit_mini" icon={<PlusOutlined />} onClick={() => show_checklist_item_add()} />
                                                </div>
                                                <List className='history_pays' itemLayout="vertical"
                                                    size="large"
                                                    pagination={{
                                                        pageSize: 5,
                                                        hideOnSinglePage: true,
                                                        showSizeChanger: false,
                                                        onChange(current, pageSize) {
                                                            setPage(current);
                                                            setPaginationSize(pageSize)
                                                        },
                                                        defaultCurrent: page
                                                    }}
                                                    locale={{
                                                        emptyText: 
                                                            <Empty 
                                                                description="The list of items is empty"
                                                            />
                                                    }}
                                                    dataSource={obj.items}
                                                    renderItem={(item, index) => (
                                                        <List.Item className={`status_${item.status}`} style={{cursor:'pointer'}} key={index} onClick={() => show_checklist_item_data(item)} >
                                                            <p>{item.name}<span>importance: {obj.importance.find((find_item) => find_item.value === item.importance)?.label}</span></p>
                                                        </List.Item>
                                                    )}
                                                />
                                            </>
                                        :
                                            <Error404_Component />
                                        }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};