import React from 'react';

export const Error403 = props => {
    return (
        <>
            <section className='container --error shadow default_padding marginTop'>
                <div className='content'>
                    <div className='error'>
                        <span>Error 403</span>
                        No access
                    </div>
                </div>
            </section>
        </>
    );
};