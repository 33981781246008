import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Collapse, Empty } from 'antd';
import { NavLink as Link } from 'react-router-dom';
import {
    SearchOutlined
} from '@ant-design/icons';

const { Panel } = Collapse;

export const UserHelp = () => {
    
    const isInitialMount = useRef(true);
    const [search_list, setSearch_list] = useState("");
    const [form_search_list] = Form.useForm();
    
    form_search_list.setFieldsValue({ 
        search: search_list
    });

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            component_start();
        }
    }, []);

    async function component_start() {
        
    }

    const help_list = [
        {
            title: 'Registration in the system',
            text: <>
                <p>To work with the platform and access the full list of services, the user must register and obtain the appropriate rights from the higher management.</p>
            </>
        },
        {
            title: 'How to create a platform user account',
            text: <>
                <ol>
                    <li>Open the authorization window by left-clicking on the <strong>«Log in»</strong> item in the upper right corner of the page.</li>
                    <li>Click on the <strong>«Create a new account»</strong> button. The <strong>«Registration»</strong> form will be displayed.</li>
                    <li>Enter your first name, last name and email address.</li>
                    <li>Click the <strong>«Create»</strong> button.</li>
                </ol>
            </>
        },
        {
            title: 'I already have an account, how do I log in?',
            text: <>
                <ol>
                    <li>Open the authorization window by left-clicking on the <strong>«Log in»</strong> item in the upper right corner of the page.</li>
                    <li>Log in under the desired user, specifying his email address and password.</li>
                </ol>
            </>
        },
        {
            title: 'Password Recovery',
            text: <>
                <ol>
                    <li>Open the authorization window by left-clicking on the <strong>«Log in»</strong> item in the upper right corner of the page.</li>
                    <li>Click on the <strong>«Forgot password?»</strong> button. The <strong>«Restore Access»</strong> form will be displayed.</li>
                    <li>Password recovery is available via email.</li>
                </ol>
                <p>Password recovery by Email.</p>
                <ol>
                    <li>Specify the email address that you used when registering your account.</li>
                    <li>Click the <strong>«Continue»</strong> button. An email with a code will be sent to the specified email address and a code entry window will open.</li>
                    <li>Enter the 6-digit code from the email.</li>
                    <li>If the correct code was specified, a window for entering a new password will open.</li>
                    <li>In the <strong>«New Password»</strong> field, enter the new password as well.</li>
                    <li>Click the <strong>«Save»</strong> button.</li>
                </ol>
            </>
        },
        {
            title: 'Password change',
            text: <>
                <ol>
                    <li>Open the <Link to='/profile' exact={ true }>user profile</Link>  page by left-clicking on the <strong>«Profile»</strong> item in the upper right corner of the page.</li>
                    <li>Log in under the desired user, specifying his email address and password.</li>
                    <li>Go to the <strong>«Security»</strong> section.</li>
                    <li>In the <strong>«Old Password»</strong>, <strong>«New Password»</strong> fields, enter the old and new password.</li>
                    <li>Click the <strong>«Save»</strong> button.</li>
                </ol>
            </>
        },
        {
            title: 'Changing the platform user',
            text: <>
                <ol>
                    <li>Click the <strong>«Exit»</strong> button in the upper right corner of the page.</li>
                    <li>Log in under the desired user, specifying his email address and password.</li>
                </ol>
            </>
        }
    ];

    var obj = {
        get help_list () {
            if ( help_list.length > 0 ) {
                let search = search_list.toLowerCase();
                return help_list.filter( function( item ) {
                    return(
                        item['title'].toLowerCase().indexOf( search ) > -1 ||
                        item['text'].toString().toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return help_list;
            }
        },
    };

    function list_search_change (e) { 
        setSearch_list(e.target.value.replace(/\s+/g,' '));
    }

    const panelStyle = {
        marginBottom: 24,
        background: 'rgba(0, 0, 0, 0.02)',
        borderRadius: 8,
        border: 'none',
    };

    return (
        <>
            <section className='container --help shadow default_padding marginTop'>
                <div className='content'>
                    <h1>Help</h1>
                    <Form name="form" className="default_form" form={form_search_list}>
                        <Form.Item key="search" name="search">
                            <Input className={`custom_input`} placeholder="Search" onChange={ list_search_change } bordered={false} 
                                prefix={
                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                }
                            />
                        </Form.Item>
                    </Form>
                    {obj.help_list.length > 0 ? 
                        <Collapse accordion bordered={false} style={{background: 'transparent'}}>
                            {obj.help_list.map((item, index) => 
                                    <Panel header={item.title} key={index} style={panelStyle}>
                                        {item.text}
                                    </Panel>
                            )}
                        </Collapse>
                    :
                        <Empty description="Nothing found" />
                    }
                </div>
            </section>
        </>
    );
};