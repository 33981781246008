import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, Card, Select, Popconfirm } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import exportFromJSON from 'export-from-json'
import HorizontalScroll from "react-horizontal-scrolling";
import { 
    getSosId,
    updSosExecutor,
    updSos
} from '../../../API/sos';
import { 
    getSitesId
} from '../../../API/sites';
import {
    PlusOutlined
} from '@ant-design/icons';
import { GoFile } from "react-icons/go";
import { MdOutlineNoPhotography } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';
import { Error404_Component } from '../../errors/404';

const { TextArea } = Input;

export const SosItem = props => {
    const dispatch = useDispatch();

    const params = useParams();
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const sos = useSelector(state => state.sos);
    const sites = useSelector(state => state.sites);

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);

    const [form_sos_data] = Form.useForm();
    const [form_sos_importance] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getSosId({
            id:params.sosID
        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getSosId]`, `color: #A366FF`, json );
            if (json?.code) {
                getSitesId({
                    id: json.result?.site_id
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                },
                {
                    label: 'Not occupied',
                    value: 'not_occupied',
                    color: '#1a7ec4'
                },
                {
                    label: 'At work',
                    value: 'at_work',
                    color: '#1a7ec4'
                },
                {
                    label: 'Occupied',
                    value: 'occupied',
                    color: '#1a7ec4'
                },
                {
                    label: 'Does not require verification',
                    value: 'not_require_verification',
                    color: '#52c41a'
                },
                {
                    label: 'There is a problem',
                    value: 'an_error',
                    color: '#f5222d'
                },
                {
                    label: 'The problem cannot be fixed',
                    value: 'cannot_be_fixed',
                    color: '#f5222d'
                },
                {
                    label: 'No problem',
                    value: 'no_error',
                    color: '#52c41a'
                },
                {
                    label: 'Fixed',
                    value: 'fixed',
                    color: '#52c41a'
                }
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get importance () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Not distributed',
                    value: '0',
                    color: '#B5B5B5'
                },
                {
                    label: 'Weak',
                    value: '1',
                    color: '#f5db22'
                },
                {
                    label: 'Average',
                    value: '2',
                    color: '#f59022'
                },
                {
                    label: 'High',
                    value: '3',
                    color: '#f5222d'
                },
            ]
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get stage () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'The layout is missing',
                    value: '0',
                    color: '#f5222d'    // Красный
                },
                {
                    label: 'The layout is received/in tasks',
                    value: '1',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'At work',
                    value: '2',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'On check with the designer',
                    value: '3',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the designer',
                    value: '4',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '5',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '6',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '7',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '8',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Passed',
                    value: '9',
                    color: '#52c41a'    // Зелёный
                },
            ]
        }
    };

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

    function getTimeSec(sec) {
        let out = '';

        let minutes = Math.floor(sec / 60);
        let seconds = +sec - (minutes * 60);

        if (minutes) {
            out += `${minutes} ${decnum(minutes,[ 'minute','minutes','minutes' ])} `;
        }

        if (seconds) {
            out += `${seconds} ${decnum(seconds,[ 'second','seconds','seconds' ])}`;
        }

        if (out == '') {
            out += `it is impossible to calculate the time`;
        }

        return out;
    };

    async function changeExecutor(v) {
        await updSosExecutor(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updSosExecutor]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The Ticket has been updated');
                getSosId({
                    id: sos.sos_id.id
                },dispatch,profile)
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function hide_sos_data() {
        form_sos_data.resetFields();
        dispatch(updPanel('sos_data'));
    }

    async function sos_cancel() {
        changeExecutor({
            id: sos.sos_id.id,
            executor: profile.data.id,
            status: 'not_occupied'
        });
        hide_sos_data();
    }

    async function show_sos_data() {
        if (sos.sos_id.executor === profile.data.id) {
            form_sos_data.resetFields();
            form_sos_data.setFieldsValue({
                id: sos.sos_id.id,
                status: sos.sos_id.status,
                comment: sos.sos_id.comment
            });
            dispatch(updPanel('sos_data'));
        } else {
            message.error('Not enough rights to perform this action');
        }
    }

    async function form_sos_data_finish(v) {
        v['id'] = sos.sos_id?.id;

        await updSos(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updSos]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Ticket updated');
                if (v['status'] === 'occupied') {
                    form_sos_data.resetFields();
                    form_sos_data.setFieldsValue({
                        id: v.id,
                        status: 'occupied',
                        comment: v['comment']
                    });
                } else {
                    hide_sos_data();
                }
                getSosId({
                    id:params.sosID
                },dispatch,profile);
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function hide_sos_importance() {
        form_sos_importance.resetFields();
        dispatch(updPanel('sos_importance'));
    }

    async function show_sos_importance(item) {
        form_sos_importance.resetFields();
        form_sos_importance.setFieldsValue({
            id: item.id
        });
        dispatch(updPanel('sos_importance'));
    }

    async function form_sos_importance_finish(v) {
        v['status'] = 'not_occupied';
        await updSos(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [updSos]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('Ticket updated');
                hide_sos_importance();
                await getSosId({
                    id:params.sosID
                },dispatch,profile);
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function sosExport() {
        let data = [];

        data.push({
            name: "Site",
            excerpt: `${sos.sos_id.site_name} (${sos.sos_id.site_domain})`,
        });

        data.push({
            name: "Executor",
            excerpt: [sos.sos_id.executor_firstname,sos.sos_id.executor_lastname].join(' ').trim(),
        });

        data.push({
            name: "Time spent",
            excerpt: getTimeSec(+sos.sos_id.date_end - +sos.sos_id.date_start ?? 0),
        });

        data.push({
            name: "Start date",
            excerpt: +sos.sos_id.date_start ? `${moment.unix(sos.sos_id.date_start).format('DD/MM/YYYY HH:mm:ss')}` : '',
        });

        data.push({
            name: "Completion date",
            excerpt: +sos.sos_id.date_end ? `${moment.unix(sos.sos_id.date_end).format('DD/MM/YYYY HH:mm:ss')}` : '',
        });

        data.push({
            name: "Importance",
            excerpt: obj.importance.find((find_item) => find_item.value === sos.sos_id.importance)?.label,
        });

        data.push({
            name: "Status",
            excerpt: obj.status.find((find_item) => find_item.value === sos.sos_id.status)?.label,
        });

        data.push({
            name: "Text",
            excerpt: sos.sos_id.problem_text,
        });

        data.push({
            name: "Wish",
            excerpt: sos.sos_id.problem_wish,
        });

        data.push({
            name: "Links",
            excerpt: sos.sos_id.problem_links,
        });

        data.push({
            name: "Dev comment",
            excerpt: sos.sos_id.comment,
        });

        const fileName = `Ticket ${sos.sos_id.site_name} (${sos.sos_id.site_domain}) - (${sos.sos_id.executor ? [sos.sos_id.executor_firstname,sos.sos_id.executor_lastname].join(' ').trim() : '-'}) from ${moment.unix(sos.sos_id.date_create).format("DD/MM/YYYY")}`;
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            name: "Title",
            excerpt: "Description"
        };

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <Modal mask={false} className="modal_custom" key="m2" width={600} title="Changing the importance of a ticket" open={app_global.panel.indexOf( 'sos_importance' ) != -1 } onOk={() => hide_sos_importance()} onCancel={() => hide_sos_importance()} footer={null}>
                <div className='header'>
                    <h2>Changing the importance of a ticket</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_sos_importance} onFinish={form_sos_importance_finish} >
                        <>
                            <Form.Item hidden name={`id`} >
                                <Input disabled={sos.loading} className='custom_input' />
                            </Form.Item>
                            <label>Status</label>
                            <Form.Item name={`importance`} >
                                <Select
                                    disabled={sos.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Select importance"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.importance.filter((status_item) => ['','0'].indexOf( status_item.value ) === -1)}
                                />
                            </Form.Item>

                            <div className='form_buttons'>
                                <Popconfirm
                                    title="Confirm execution"
                                    description="Do you really want to perform this action?"
                                    onConfirm={() => {
                                        form_sos_importance.submit()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button className='submit' htmlType="submit" loading={sos.loading}>Save</Button>
                                </Popconfirm>
                            </div>
                        </>
                    </Form>
                </div>
            </Modal>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Closing a ticket" open={app_global.panel.indexOf( 'sos_data' ) != -1 } onOk={() => hide_sos_data()} onCancel={() => hide_sos_data()} footer={null}>
                <div className='header'>
                    <h2>Closing a ticket</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_sos_data} onFinish={form_sos_data_finish} >
                        <>
                            <Form.Item hidden name={`id`} >
                                <Input disabled={sos.loading} className='custom_input' />
                            </Form.Item>
                            <label>Status</label>
                            <Form.Item name={`status`} >
                                <Select
                                    disabled={sos.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Select status"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.status.filter((status_item) => ['','none','publish','draft','cancel','an_error','at_work','not_occupied'].indexOf( status_item.value ) === -1)}
                                />
                            </Form.Item>
                            <label>Comment</label>
                            <Form.Item name={`comment`} >
                                <TextArea disabled={sos.loading} className='custom_input' placeholder="Comment" rows={4} showCount  maxLength={1000} />
                            </Form.Item>

                            <div className='form_buttons'>
                                <Popconfirm
                                    title="I refuse the task"
                                    description="Do you really want to perform this action?"
                                    onConfirm={() => sos_cancel()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button className="button_left" style={{backgroundColor:'#ff4f59'}} loading={sos.loading}>Refuse to execute</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Confirm this completion"
                                    description="Do you really want to perform this action?"
                                    onConfirm={() => {
                                        form_sos_data.submit()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button className='submit' htmlType="submit" loading={sos.loading}>Save</Button>
                                </Popconfirm>
                            </div>
                        </>
                    </Form>
                </div>
            </Modal>
            <section className='container --sos_item shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>Viewing an ticket</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                {['3','4'].indexOf( profile?.data?.type ) !== -1 ?
                                    <div className='left_form'>
                                        <div className='default_form'>
                                            <div className='form_buttons' style={{marginTop:0}}>
                                                <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/sos`} ><span>Go back</span></Link>
                                            </div>
                                        </div>
                                        {(!sites.loading && Object.keys(sites.site_id).length) ?
                                            <div className='info_data'>
                                                <div className='left'>
                                                    <div className={`spec_item_left ${sites.site_id.site_preview ? sites.site_id.site_preview : 'no_photo'}`}>
                                                        {sites.site_id.site_preview ? 
                                                            <img src={`${API}/uploads/${sites.site_id.site_preview}`} /> 
                                                        : 
                                                            <MdOutlineNoPhotography />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='right'>
                                                    <div className="org_name">
                                                        <span className="name">{sites.site_id?.name}</span>
                                                    </div>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === sites.site_id?.country)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === sites.site_id?.engine)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of copyright protection: </b>{+sites.site_id?.copyright ? moment.unix(sites.site_id?.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                        </p>
                                                        <p className='category'>
                                                            <span style={{backgroundColor:obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.color}}>{obj.status.find((find_item) => find_item.value === sites.site_id?.status)?.label}</span>
                                                            <span>{sites.site_id?.domain}</span>
                                                        </p>
                                                        <p className='category'>
                                                            <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => window.open(`https://${sites.site_id.domain}/`, '_blank')}>
                                                                Visit the website
                                                            </span>  
                                                            <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => window.open(`/sites/${sites.site_id.id}`, '_blank')}>
                                                                Visit the site card
                                                            </span>  
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='bottom'>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>Description: </b>{sites.site_id?.excerpt}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>References to materials: </b>{sites.site_id?.material}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Site Features: </b>{sites.site_id?.features}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Contact details: </b>{sites.site_id?.contacts}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>SEO data: </b>{sites.site_id?.seo}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Where does the data from the feedback forms go: </b>{sites.site_id?.form}</span>
                                                        </p>
                                                    </div>
                                                    {(sos.sos_id.status !== 'not_occupied' && sos.sos_id.status !== 'occupied') ? 
                                                        <div className='default_form'>
                                                            <div className='form_buttons' >
                                                                <Button className='submit' style={{width:'100%'}} onClick={() => sosExport()} loading={sos.loading}>Export to CSV</Button>
                                                            </div>
                                                        </div>
                                                    :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <Skeleton />
                                        }
                                    </div>
                                :
                                    null
                                }
                                <div className='right_content' style={{maxWidth:['3','4'].indexOf( profile?.data?.type ) !== -1 ? '':'100%'}}>
                                    {['3','4'].indexOf( profile?.data?.type ) !== -1 ?
                                        (!sos.loading && Object.keys(sos.sos_id).length) ?
                                            <div className='info_data'>
                                                <div className='left'>
                                                    <div className={`spec_item_left ${sos.sos_id.site_preview ? sos.sos_id.site_preview : 'no_photo'}`}>
                                                        {sos.sos_id.site_preview ? 
                                                            <img src={`${API}/uploads/${sos.sos_id.site_preview}`} /> 
                                                        : 
                                                            <MdOutlineNoPhotography />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='right'>
                                                    <div className="org_name">
                                                        <span className="name">{sos.sos_id.problem_title} from {moment.unix(sos.sos_id.date_create).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <div className='org_info'>
                                                        <p className='experience'>
                                                            <span><b>Domain: </b>{sos.sos_id.site_name}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Description: </b>{sos.sos_id.site_excerpt}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === sos.sos_id.site_country)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === sos.sos_id.site_engine)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Date of copyright protection: </b>{+sos.sos_id.site_copyright ? moment.unix(sos.sos_id.site_copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Importance: </b>{obj.importance.find((find_item) => find_item.value === sos.sos_id.importance)?.label}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Text: </b>{sos.sos_id.problem_text}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Wish: </b>{sos.sos_id.problem_wish}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Links: </b>{sos.sos_id.problem_links}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Dev comment: </b>{sos.sos_id.comment}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>Executor: </b>{sos.sos_id.executor ? [sos.sos_id.executor_firstname,sos.sos_id.executor_lastname].join(' ').trim() : 'missing'}</span>
                                                        </p>
                                                        <p className='experience'>
                                                            <span><b>It took time to complete the task: </b>{getTimeSec(sos.sos_id.time ?? 0)}</span>
                                                        </p>
                                                        <p className='category'>
                                                            <span style={{backgroundColor:obj.importance.find((find_item) => find_item.value === sos.sos_id.importance)?.color}}>
                                                                {obj.importance.find((find_item) => find_item.value === sos.sos_id.importance)?.label}
                                                            </span>
                                                            <span style={{backgroundColor:obj.status.find((find_item) => find_item.value === sos.sos_id.status)?.color}}>
                                                                {obj.status.find((find_item) => find_item.value === sos.sos_id.status)?.label}
                                                            </span>
                                                            <span>
                                                                {sos.sos_id.site_domain}
                                                            </span>
                                                        </p>
                                                        {["3","4"].indexOf( profile?.data?.type ) !== -1 ?
                                                            <p className='category'>
                                                                {sos.sos_id.importance === '0' ? 
                                                                    <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => show_sos_importance(sos.sos_id)}>
                                                                        Change the importance
                                                                    </span>
                                                                :
                                                                    sos.sos_id.status === 'not_occupied' ?
                                                                        <span style={{cursor:'pointer',backgroundColor:'#52c41a'}} onClick={() => changeExecutor({
                                                                            id: sos.sos_id.id,
                                                                            executor: profile.data.id,
                                                                            status: 'occupied'
                                                                        })}>
                                                                            Take a task
                                                                        </span>
                                                                    :
                                                                        sos.sos_id.executor === profile.data.id ?
                                                                            sos.sos_id.status === 'occupied'  ?
                                                                                <>
                                                                                    <span style={{cursor:'pointer'}} onClick={() => show_sos_data()}>
                                                                                        Complete
                                                                                    </span>
                                                                                    <Popconfirm
                                                                                        title="Cancel this ticket"
                                                                                        description="Do you really want to perform this action?"
                                                                                        onConfirm={() => changeExecutor({
                                                                                            id: sos.sos_id.id,
                                                                                            executor: profile.data.id,
                                                                                            status: 'not_occupied'
                                                                                        })}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <span style={{cursor:'pointer',backgroundColor:'#f5222d'}} >
                                                                                            Cancel
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                </>
                                                                            :
                                                                                null
                                                                        :
                                                                            null
                                                                }
                                                                <span style={{cursor:'pointer'}} onClick={() => window.open(`https://${sos.sos_id.site_domain}/`, '_blank')}>
                                                                    Visit the website
                                                                </span>   
                                                            </p>
                                                        :
                                                            null
                                                        }
                                                        {sos.sos_id.files.length ? 
                                                            <>
                                                                <p className='experience'>
                                                                    <span><b>Files</b></span>
                                                                </p>
                                                                {Object.keys(sos.sos_id.files).length ?
                                                                    <div className='scroll_files'>
                                                                        <HorizontalScroll className="scroll" reverseScroll={true}>
                                                                            <div className="scroller">
                                                                                {sos.sos_id.files.map((item, index) => 
                                                                                    <Card key={index} size="small" title={`#${item.id} ${moment.unix(item.date_create).format("DD/MM/YYYY")}`} style={{ width: 200, marginTop: 0 }}>
                                                                                        <GoFile onClick={() => window.open(`${API}/uploads/${item.file}`, '_blank')} style={{cursor:'pointer'}} className='image' />
                                                                                    </Card>
                                                                                )}
                                                                            </div>
                                                                        </HorizontalScroll>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </>
                                                        :
                                                            null   
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <Skeleton />
                                    :
                                        null
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};