import React from 'react';
import { Helmet } from "react-helmet";
import './error.style';
import { Error404 } from './error';

export class Error404_Component extends React.Component {
    constructor( props ) {
        super();
    }

    static async fetchData() {
        return {
            title: 'Page not found',
            description: 'Error 404. The requested page could not be found',
            error_code: 404
        };
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{`Page not found`}</title>
                    <meta property='og:title' content={`Page not found`} />
                    <meta name="description" content={`Error 404. The requested page could not be found`} />
                    <meta name="og:description" content={`Error 404. The requested page could not be found`} />
                </Helmet>
                <Error404 />
            </>
        );
    }
}