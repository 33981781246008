import axios from "axios";
import { API } from '../params';

const Request = async (data, type, file = 'index.php', profile = {}) => {

    let URL = `${API}/${file}`;

    var response = {}
    switch(type) {
        case 'get':
            response = await axios.get(URL, data);
            break;
        case 'post':
            response = await axios.post(URL, data);
            break;
        default:

            break;
    }
    if ('code' in response.data) {
        return response.data;
    } else {
        return {
            error: [
                'Неизвестная ошибка',
                "",
                "",
                []
            ]
        };
    }
}

export const RequestFile = async (data, file = 'index.php', profile = {}) => {

    let URL = `${API}/${file}`;

    var response = {};
    response = await axios.post(URL, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    if ('code' in response.data) {
        return response.data;
    } else {
        return {
            error: [
                'Неизвестная ошибка',
                "",
                "",
                []
            ]
        };
    }
}

export default Request;