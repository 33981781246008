const API = process.env.NODE_ENV === 'development' ? 'https://api.dev.tc-r2.uz' : 'https://api.dev.tc-r2.uz';

const UPLOADS = `${API}/uploads`;

const FILES = `${API}/files`;

const IMAGES = `${API}/images`;

const SETTINGS_APP = {
    project_format: 'RU',
    mask: {
        'RU': {
            'account': ['+', 7,'(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            'default': ['+', /(7|8)/,'(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            'placeholder': '+7(999)999-9999',
        },
        'UZ': {
            'account': ['+', 9, 9, 8, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
            'default': ['+', 9, 9, 8, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
            'placeholder': '+998 99 999 99 99'
        },
    },
    currency: {
        'RU': {
            'label': '₽',
            'value': 'rub',
        },
        'UZ': {
            'label': 'UZS',
            'value': 'uzs',
        },
    }
};

export {
    API,
    UPLOADS,
    FILES,
    IMAGES,
    SETTINGS_APP
}