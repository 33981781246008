// Global
import React, { useRef, useState, useEffect } from "react";
import { NavLink as Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import Loadable from 'react-loadable';
import moment from 'moment';

// Params and data
import { API, UPLOADS, SETTINGS_APP } from "../../params";
import { upload_config_one_image, upload_config_one_png_image, upload_config_multi_image, upload_config_multi_pdf } from "../../API/upload";
import users from '../header/users';

// Editor
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// Components
import { Skeleton, Tabs, Form, Input, Select, List, Modal, Empty, Badge, Tooltip, Grid, Popconfirm, Space, Typography, Tag, Progress, Image, Upload, Button, message, InputNumber, Rate } from 'antd';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import ReactCodeInput from "react-code-input";
import ImgCrop from 'antd-img-crop';

// Mask
import MaskedInput, {conformToMask} from 'react-text-mask';
import InputMask from 'react-input-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

// Icons
import {
    SearchOutlined,
    PlusOutlined,
    StarFilled
} from '@ant-design/icons';
import { VscFilePdf } from "react-icons/vsc";
import { FiEdit, FiTrash2 } from "react-icons/fi";

// Redux
import { updPanel } from '../../redux/app_global/reducer';
import { setToken, setAuth, deleteData } from '../../redux/profile/reducer';

// API
import { 
    getProfile, getUsers,
    updProfile, updProfileEmail, updProfilePassword, updUser,
    confirmProfileEmail, confirmProfileEmailCode
} from "../../API/profile";

// Localization of components
moment.locale('ru');

// Loading the library for SSR
let htmlToDraft = null;
if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
}

// Other components
const { useBreakpoint } = Grid;
const { TextArea } = Input;
const { Text} = Typography;
const { Dragger } = Upload;

// Page Component
export const Profile = () => {
    // Hooks
    const screens = useBreakpoint();
    const dispatch = useDispatch();

    // Cookies
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    // Redux
    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);

    // States
    // Global
    const [activeTab, setActiveTab] = useState("profile");

    // Profile
    const [password_complexity, setPassword_complexity] = useState(0);

    // Users
    const [users_list_search, setUsers_list_search] = useState("");

    // Forms
    // Profile
    const [form_profile] = Form.useForm();
    const [form_email] = Form.useForm();
    const [form_password] = Form.useForm();

    // Users
    const [form_users_list_search] = Form.useForm();
    const [form_users_data] = Form.useForm();

    // Filling out search forms

    // Page component loading status
    const isInitialMount = useRef(true);

    // Page Component Rendering event
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

        }
    }, []);

    // The SSR Editor Loader Component
    function Loading(props) {
        if (props.error) {
            return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
        } else if (props.pastDelay) {
            return <div>Loading...</div>;
        } else {
            return null;
        }
    }

    // SSR Editor Loader
    const ReactEditorJS = Loadable({
        loader: () => import('../editors/default'),
        render(loaded, props) {
            let Component = loaded.CustomEditor;
            return <Component {...props} />;
        },
        loading: Loading,
    });

    // Функция заполнения формы профиля
    show_tab_profile();

    // Выход
    async function logout() {
        message.error('The session is closed');
        removeCookie('token', { path: '/' });
        dispatch(setToken(''));
        dispatch(setAuth(''));
        dispatch(deleteData());
    }

    // Вывод номера в нужном формате
    function customPhoneMask(phone = "") {
        var end = "";
        if (phone !== null) {
            var length = phone.length;
            
            switch(SETTINGS_APP.project_format) {
                case 'UZ':
                    if(length > 0) { if (phone[0] === '9') { end += '+'; } end += phone[0]; }
                    if(length > 1) { end += phone[1]; }
                    if(length > 2) { end += phone[2]; }
                    if(length > 3) { end += " "+phone[3]; }
                    if(length > 4) { end += phone[4]; }
                    if(length > 5) { end += " "+phone[5]; }
                    if(length > 6) { end += phone[6]; }
                    if(length > 7) { end += phone[7]; }
                    if(length > 8) { end += " "+phone[8]; }
                    if(length > 9) { end += phone[9]; }
                    if(length > 10) { end += " "+phone[10]; }
                    if(length > 11) { end += phone[11]; }
                    break;
                default:
                    if(length > 0) { if (phone[0] === '7') { end += '+'; } end += phone[0]; }
                    if(length > 1) { end += "("+phone[1]; }
                    if(length > 2) { end += phone[2]; }
                    if(length > 3) { end += phone[3]+")"; }
                    if(length > 4) { end += phone[4]; }
                    if(length > 5) { end += phone[5]; }
                    if(length > 6) { end += phone[6]; }
                    if(length > 7) { end += "-"+phone[7]; }
                    if(length > 8) { end += phone[8]; }
                    if(length > 9) { end += "-"+phone[9]; }
                    if(length > 10) { end += phone[10]; }
            }
        } else {
            end = "The number is not filled in";
        }

        return end;
    }

    /* Переключатели разделов Start */
        // Глобальный
        const tabChange = (key) => {
            setActiveTab(key);
            switch(key) {
                case 'profile':
                    show_tab_profile(key);
                    break;
                case 'safety':
                    show_tab_safety(key);
                    break;
                case 'users':
                    show_tab_users_list();
                    break;
            }
        };
    /* Переключатели разделов Stop */

    /* Профиль Start */
        // Открытие вкладки профиля
        async function show_tab_profile(key) {
            form_profile.resetFields();
            form_profile.setFieldsValue({
                firstname: profile.data.firstname,
                lastname: profile.data.lastname,
                patronymic: profile.data.patronymic,
                gender: profile.data.gender,
                type: profile.data?.type,
                type_dev: profile.data?.type_dev
            });
        }

        // Отправка формы обновления профиля
        async function form_finish_profile(v) {
            await updProfile(v,dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [updProfile]`, `color: #A366FF`, json );
                if (json?.code) {
                    message.success('Profile updated');
                    await getProfile({},dispatch,profile)
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }

        // Отправка формы подтверждения email
        async function form_finish_profile_email_confirm(e) {
            if (e.length === 6) {
                await confirmProfileEmailCode({code:e},dispatch,profile)
                .then(async json => {
                    console.log ( `%c [API]: [confirmProfileEmailCode]`, `color: #A366FF`, json );
                    if (json?.code) {
                        dispatch( updPanel('confirmProfileEmail') );
                        message.success('Email confirmed');
                        await getProfile({},dispatch,profile)
                    } else {
                        let error = [];
                        if (json.error[0]) {
                            error.push(json.error[0]);
                        }
                        if (json.error[1]) {
                            error.push(json.error[1]);
                        }
                        if (json.error[3]) {
                            error.push((json.error[3]).join(', '));
                        }
                        message.error(error.join('. '));
                    }
                });
            }
        }
    /* Профиль End */

    /* Безопасность Start */
        // Открытие вкладки безопасность
        async function show_tab_safety(key) {
            form_email.resetFields();
            form_email.setFieldsValue({
                email: profile.data.email
            });

            setPassword_complexity(0);
            form_password.resetFields();
        }

        // Открытие модального окна подтверждения email
        async function clickProfileConfirmEmail() {
            await confirmProfileEmail({},dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [confirmProfileEmail]`, `color: #A366FF`, json );
                if (json?.code) {
                    dispatch( updPanel('confirmProfileEmail') );
                    message.success('An email with a code has been sent to your email');
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }

        // Закрытие модального окна подтверждения email
        async function hide_modal_confirm_profile_email() {
            dispatch( updPanel('confirmProfileEmail') );
        }

        // Отправка формы сохранения email
        async function form_finish_profile_email(v) {
            await updProfileEmail(v,dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [updProfileEmail]`, `color: #A366FF`, json );
                if (json?.code) {
                    message.success('Mail changed');
                    await getProfile({},dispatch,profile)
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }

        // Отправка формы сохранения пароля
        async function form_finish_profile_password(v) {
            if (v['password'] === v['password_new']) {
                message.error('You wrote the same passwords');
                return false;
            }
            if (checkPassword(v['password_new']) < 100) {
                message.error('The password is not complex enough');
                return false;
            }

            await updProfilePassword(v,dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [updProfilePassword]`, `color: #A366FF`, json );
                if (json?.code) {
                    message.success('Password changed');
                    await getProfile({},dispatch,profile)
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }
    /* Безопасность End */

    /* Пользователи Start */
        // Открытие модального окна пользователя
        async function show_tab_users_item(v) {
            form_users_data.resetFields();
            form_users_data.setFieldsValue({
                id: v?.id,
                firstname: v?.firstname,
                lastname: v?.lastname,
                patronymic: v?.patronymic,
                gender: v?.gender,
                type: v?.type,
                type_dev: v?.type_dev
            });
            dispatch( updPanel('users_item') );
        }

        // Закрытие модального окна пользователя
        async function hide_tab_users_item() {
            dispatch( updPanel('users_item') );
        }

        // Изменение текста поисковой строки в списке пользователей
        function users_list_search_change (e) { 
            setUsers_list_search(e.target.value.replace(/\s+/g,' '));
        }

        // Отправка формы сохранения пользователя
        async function form_finish_users_data(v) {
            await updUser(v,dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [updUser]`, `color: #A366FF`, json );
                if (json?.code) {
                    message.success('User changed');
                    await getUsers({},dispatch,profile)
                    .then(async json => {
                        console.log ( `%c [API]: [getUsers]`, `color: #A366FF`, json );
                        if (json?.code) {
                            //Done
                        } else {
                            let error = [];
                            if (json.error[0]) {
                                error.push(json.error[0]);
                            }
                            if (json.error[1]) {
                                error.push(json.error[1]);
                            }
                            if (json.error[3]) {
                                error.push((json.error[3]).join(', '));
                            }
                            message.error(error.join('. '));
                        }
                    });
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }
        
        // Открытие списка пользователей
        async function show_tab_users_list() {
            await getUsers({},dispatch,profile)
            .then(async json => {
                console.log ( `%c [API]: [getUsers]`, `color: #A366FF`, json );
                if (json?.code) {
                    //Done
                } else {
                    let error = [];
                    if (json.error[0]) {
                        error.push(json.error[0]);
                    }
                    if (json.error[1]) {
                        error.push(json.error[1]);
                    }
                    if (json.error[3]) {
                        error.push((json.error[3]).join(', '));
                    }
                    message.error(error.join('. '));
                }
            });
        }
    /* Пользователи End */
    
    // Случайный пользователь
    const randomUser = users[Math.floor(Math.random() * (users.length - 0)) + 0];

    // Статусы организаций
    const getOrgStatus = (status) => {
        var list = {
            'publish': {
                text: 'Published',
                color: '#52c41a'
            },
            'moderation': {
                text: 'On inspection',
                color: '#faad14'
            },
            'draft': {
                text: 'Draft',
                color: '#8c6722'
            },
            'cancel': {
                text: 'Cancelled',
                color: '#f5222d'
            },
        };
        return <Tooltip title={list[status]['text']} ><Badge style={{marginRight:10}} color={list[status]['color']} /></Tooltip>
    }

    // Теги статусов организаций
    const getOrgStatusTag = (status) => {
        var list = {
            'publish': {
                text: 'Published',
                color: '#52c41a'
            },
            'moderation': {
                text: 'On inspection',
                color: '#faad14'
            },
            'draft': {
                text: 'Draft',
                color: '#8c6722'
            },
            'cancel': {
                text: 'Cancelled',
                color: '#f5222d'
            },
        };
        if (status !== undefined) {
            return <Tag color={list[status]['color']} >{list[status]['text']}</Tag>
        }
        return <Tag color="#424242" >Is unknown</Tag>
    }

    // Проверка пароля
    function checkPassword(e = '') {
        var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
        var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
        var digits = "0123456789"; // Цифры
        var specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы

        var is_s = false; // Есть ли в пароле буквы в нижнем регистре
        var is_b = false; // Есть ли в пароле буквы в верхнем регистре
        var is_d = false; // Есть ли в пароле цифры
        var is_sp = false; // Есть ли в пароле спецсимволы
                
        for (var i = 0; i < e.length; i++) {
            /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
            if (!is_s && s_letters.indexOf(e[i]) != -1) {
                is_s = true
            }
            else if (!is_b && b_letters.indexOf(e[i]) != -1) {
                is_b = true
            }
            else if (!is_d && digits.indexOf(e[i]) != -1) {
                is_d = true
            }
            else if (!is_sp && specials.indexOf(e[i]) != -1) {
                is_sp = true
            }
        }

        var rating = 0;
        if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
        if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
        if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
        if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
        /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */

        var score = 0;
        if (e.length < 6 && rating < 3) {
            score = 10;
        }
        if (e.length === 0) {
            score = 0;
        }
        else if (e.length < 6 && rating >= 3) {
            score = 50;
        }
        else if (e.length >= 8 && rating < 3) {
            score = 50;
        }
        else if (e.length >= 8 && rating >= 3) {
            score = 100;
        }
        else if (e.length >= 6 && rating == 1) {
            score = 10;
        }
        else if (e.length >= 6 && rating > 1 && rating < 4) {
            score = 50;
        }
        else if (e.length >= 6 && rating == 4) {
            score = 100;
        };

        setPassword_complexity(score);
        return score;
    }

    // Редактирование пароля
    function onChangePassword(e) {
        checkPassword(e.target.value);
    }

    // Поле времени
    function TimeInput(props) {
        let mask = 'dD.mM.YYYY';
        let formatChars = {
            'Y': '[0-9]',
            'd': '[0-3]',
            'D': '[0-9]',
            'm': '[0-1]',
            'M': '[1-9]'
        };
      
        let beforeMaskedValueChange = (newState, oldState, userInput) => {
            let { value } = newState;
        
            let dateParts = value.split('.');
            let dayPart = dateParts[0];
            let monthPart = dateParts[1];
        
            // Conditional mask for the 2nd digit of day based on the first digit
            if(dayPart.startsWith('3'))
                formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
            else if(dayPart.startsWith('0'))
                formatChars['D'] = '[1-9]'; // To block 00.
            else
                formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.
        
        
            // Conditional mask for the 2nd digit of month based on the first digit
            if(monthPart.startsWith('1'))
                formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
            else
                formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.
        
            return {value, selection: newState.selection};
        }
        return (
            <InputMask
                className='ant-input custom_input'
                mask={mask}
                value={props.value}
                onChange={props.onChange}
                formatChars={formatChars}
                beforeMaskedValueChange={beforeMaskedValueChange}>
            </InputMask>
        );
    }

    // Поле времени
    function TimeTimeInput(props) {
        let mask = 'Hh:Ii';
        let formatChars = {
            'H': '[0-2]',
            'h': '[0-9]',
            'I': '[0-5]',
            'i': '[0-9]'
        };
      
        let beforeMaskedValueChange = (newState, oldState, userInput) => {
            let { value } = newState;
        
            // Conditional mask for the 2nd digit base on the first digit
            if(value.startsWith('2'))
              formatChars['2'] = '[0-3]'; // To block 24, 25, etc.
            else
              formatChars['2'] = '[0-9]'; // To allow 05, 12, etc.
            return {value, selection: newState.selection};
        }
        return (
            <InputMask
                className='ant-input custom_input'
                mask={mask}
                value={props.value}
                onChange={props.onChange}
                formatChars={formatChars}
                beforeMaskedValueChange={beforeMaskedValueChange}>
            </InputMask>
        );
    }

    /* Объект списков Start */
    var obj = {
        get users () {
            if ( profile.users.length > 0 ) {
                let search = users_list_search.toLowerCase();
                return profile.users.filter( function( item ) {
                    return(
                        [item?.lastname,item?.firstname].join(' ').replace(/ +/g, ' ').trim().toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return profile.users;
            }
        },
    }

    // Изменение отстутов
    function returnText(text) { return !text ? "" : text.replace('\r\n',"<br />"); }
    // 
    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }
    
    return (
        <>
            <Modal mask centered className="modal_custom" key="m6" width={700} title="Email Confirmation" open={app_global.panel.indexOf( 'confirmProfileEmail' ) != -1 } onOk={() => hide_modal_confirm_profile_email()} onCancel={() => hide_modal_confirm_profile_email()} footer={null}>
                <div className='header'>
                    <h2>Email Confirmation</h2>
                    <h3>Enter the code from the email</h3>
                </div>
                <div className="custom_divider"></div>
                <div className='body'>
                    {profile.auth ?
                        <div className="block_pin">
                            {profile.loading ?
                                <Skeleton.Button style={{marginBottom: '20px',width:260,height:50}} shape="round" active block={false} />
                            :
                                <ReactCodeInput
                                    type="number"
                                    fields={6}
                                    onChange={(e) => form_finish_profile_email_confirm(e)}
                                />
                            }
                        </div>
                        :
                        <>
                            <div className='result'>
                                <>
                                    <h3>You are not logged in</h3>
                                </>
                            </div>
                        </>
                    }
                </div>
                <div className='footer'>
                    
                </div>
            </Modal>
            <Modal mask={false} className="modal_custom" key="m30" width={900} title="Changing the user" open={app_global.panel.indexOf( 'users_item' ) != -1 } onOk={() => hide_tab_users_item()} onCancel={() => hide_tab_users_item()} footer={null}>
                <div className='header'>
                    <h2>Changing the user</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <Form name="form" className="default_form" form={form_users_data} onFinish={form_finish_users_data}>
                        <Form.Item hidden name={`id`} rules={[{ required: true, message: `Please enter the user ID!` }]}>
                            <Input disabled={profile.loading} className='custom_input' />
                        </Form.Item>
                        <label style={{marginTop:20}}>First name</label>
                        <Form.Item name={`firstname`} rules={[{ required: true, message: `Please enter a first name!` }]}>
                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['firstname']} />
                        </Form.Item>
                        <label>Last name</label>
                        <Form.Item name={`lastname`} >
                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['lastname']} />
                        </Form.Item>
                        <label>Middle name</label>
                        <Form.Item name={`patronymic`} >
                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['patronymic']} />
                        </Form.Item>
                        <label>Gender</label>
                        <Form.Item name={`gender`} >
                            <Select
                                disabled={profile.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choose a gender"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {value:"1",label:"Male"},
                                    {value:"2",label:"Female"},
                                    {value:"3",label:"Not specified"}
                                ]}
                            />
                        </Form.Item>
                        <label>Role</label>
                        <Form.Item name={`type`} rules={[{ required: true, message: `Please specify the role` }]} >
                            <Select
                                disabled={profile.loading}
                                className="custom_select"
                                showSearch
                                placeholder="Choose a role"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {value:"1",label:"Regular user"},
                                    {value:"2",label:"Tester"},
                                    {value:"3",label:"Developer"},
                                    {value:"4",label:"Administrator"}
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues[`type`] !== currentValues[`type`]}
                        >
                            {({ getFieldValue }) =>
                                (['3','4'].indexOf( getFieldValue(`type`) ) !== -1)  ? (
                                    <>
                                        <label>Developer Level</label>
                                        <Form.Item name={`type_dev`} rules={[{ required: true, message: `Please select a level` }]}>
                                            <Select
                                                disabled={profile.loading}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select a level"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                    {value:"0",label:"Not selected"},
                                                    {value:"1",label:"Junior"},
                                                    {value:"2",label:"Middle"},
                                                    {value:"3",label:"Senior"},
                                                    {value:"4",label:"Lead"}
                                                ]}
                                            />
                                        </Form.Item>
                                    </>
                                ) : null
                            }
                        </Form.Item>
                        <div className='form_buttons'>
                            <Button className='submit' htmlType="submit" loading={profile.loading}>Save</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <section className='container --profile marginTop'>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        <>
                            {
                                profile.auth ?
                                <div className="profile_content">
                                    <div className="profile_right default_shadow">
                                        <div className="custom-tabs">
                                            <Tabs
                                                tabPosition={Object.entries(screens)
                                                    .filter((screen) => !!screen[1])
                                                    .map((screen) => (
                                                        screen[0]
                                                )).indexOf( 'xl' ) != -1 ? 'left' : 'top'}
                                                activeKey={activeTab}
                                                defaultActiveKey="profile"
                                                onChange={tabChange}
                                                className="profile_tabs"
                                                items={[
                                                    {
                                                        key: 'profile',
                                                        label: `Profile`,
                                                        children: 
                                                        <div>
                                                            {profile.loading ? 
                                                                <Skeleton active />
                                                            :
                                                                <div>
                                                                    <Form name="form" className="default_form" form={form_profile} onFinish={form_finish_profile}>
                                                                        <label style={{marginTop:20}}>First name</label>
                                                                        <Form.Item name={`firstname`} rules={[{ required: true, message: `Please enter your name!` }]}>
                                                                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['firstname']} />
                                                                        </Form.Item>
                                                                        <label>Last name</label>
                                                                        <Form.Item name={`lastname`} >
                                                                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['lastname']} />
                                                                        </Form.Item>
                                                                        <label>Middle name</label>
                                                                        <Form.Item name={`patronymic`} >
                                                                            <Input disabled={profile.loading} className='custom_input' placeholder={randomUser['patronymic']} />
                                                                        </Form.Item>
                                                                        <label>Gender</label>
                                                                        <Form.Item name={`gender`} >
                                                                            <Select
                                                                                disabled={profile.loading}
                                                                                className="custom_select"
                                                                                showSearch
                                                                                placeholder="Choose a gender"
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                }
                                                                                options={[
                                                                                    {value:"1",label:"Male"},
                                                                                    {value:"2",label:"Female"},
                                                                                    {value:"3",label:"Not specified"}
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                        <label>Role</label>
                                                                        <Form.Item name={`type`} rules={[{ required: true, message: `Please specify the role` }]} >
                                                                            <Select
                                                                                disabled={true}
                                                                                className="custom_select"
                                                                                showSearch
                                                                                placeholder="Choose a role"
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                }
                                                                                options={[
                                                                                    {value:"1",label:"Regular user"},
                                                                                    {value:"2",label:"Tester"},
                                                                                    {value:"3",label:"Developer"},
                                                                                    {value:"4",label:"Administrator"}
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            noStyle
                                                                            shouldUpdate={(prevValues, currentValues) => prevValues[`type`] !== currentValues[`type`]}
                                                                        >
                                                                            {({ getFieldValue }) =>
                                                                                (['3','4'].indexOf( getFieldValue(`type`) ) !== -1)  ? (
                                                                                    <>
                                                                                        <label>Developer Level</label>
                                                                                        <Form.Item name={`type_dev`} rules={[{ required: true, message: `Please select a level` }]}>
                                                                                            <Select
                                                                                                disabled={true}
                                                                                                className="custom_select"
                                                                                                showSearch
                                                                                                placeholder="Select a level"
                                                                                                optionFilterProp="children"
                                                                                                filterOption={(input, option) =>
                                                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                                }
                                                                                                options={[
                                                                                                    {value:"0",label:"Not selected"},
                                                                                                    {value:"1",label:"Junior"},
                                                                                                    {value:"2",label:"Middle"},
                                                                                                    {value:"3",label:"Senior"},
                                                                                                    {value:"4",label:"Lead"}
                                                                                                ]}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </Form.Item>
                                                                        <div className='form_buttons'>
                                                                            <Button className='submit' htmlType="submit" loading={profile.loading}>Save</Button>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            }
                                                        </div>
                                                    },
                                                    {
                                                        key: 'safety',
                                                        label: `Safety`,
                                                        children: 
                                                        <div>
                                                            {profile.loading ? 
                                                                <Skeleton active />
                                                            :
                                                                <div>
                                                                    <Form name="form" className="default_form" form={form_email} onFinish={form_finish_profile_email} >
                                                                        <label style={{marginTop:20}}>E-mail</label>
                                                                        <Form.Item name={`email`} rules={[{ required: true, message: `Please enter email!` }]}>
                                                                            <MaskedInput
                                                                                disabled={profile.loading}
                                                                                mask={emailMask}
                                                                                placeholder="mail@mail.ru"
                                                                                render={(ref, props) => (
                                                                                    <span class="ant-input-affix-wrapper custom_input ant-input-affix-wrapper-borderless">
                                                                                        <input className="ant-input custom_input" type="text" aria-required="true" ref={ref} {...props} />
                                                                                        {profile.data?.email_confirm === "0" ?
                                                                                            <span style={{display:'none'}}class="ant-input-suffix">
                                                                                                <button type="button" class="ant-btn ant-btn-default submit_mini" onClick={() => clickProfileConfirmEmail()}><span>Confirm</span></button>
                                                                                            </span>
                                                                                        :
                                                                                            null
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            />
                                                                        </Form.Item>
                                                                        <div className='form_buttons'>
                                                                            <Button className='submit' htmlType="submit" loading={profile.loading}>Save</Button>
                                                                        </div>
                                                                    </Form>

                                                                    <Form name="form" className="default_form" form={form_password} onFinish={form_finish_profile_password} >
                                                                        <label style={{marginTop:20}}>Old password</label>
                                                                        <Form.Item name={`password`} rules={[{ required: true, message: `Please enter your old password!` }]}>
                                                                            <Input.Password disabled={profile.loading} className='custom_input' placeholder={`•••••••••`} />
                                                                        </Form.Item>
                                                                        <label>New password</label>
                                                                        <Form.Item name={`password_new`} rules={[{ required: true, message: `Please enter a new password!` }]}>
                                                                            <Input.Password onChange={(e) => onChangePassword(e)} disabled={profile.loading} className='custom_input' placeholder={`•••••••••`} />
                                                                        </Form.Item>
                                                                        <Progress percent={password_complexity} />
                                                                        <div className='form_buttons'>
                                                                            <Button className='submit' htmlType="submit" loading={profile.loading}>Save</Button>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            }
                                                        </div>
                                                    },
                                                    {
                                                        key: 'users',
                                                        label: `Users`,
                                                        disabled: ["4"].indexOf( profile?.data?.type ) === -1,
                                                        children: 
                                                        <div>
                                                            {app_global.loading ?
                                                                <Skeleton active />
                                                            :
                                                                <>
                                                                    <Form name="form" className="default_form" form={form_users_list_search}>
                                                                        <Form.Item key="search" name="search">
                                                                            <Input className={`custom_input`} placeholder="Search" onChange={ users_list_search_change } bordered={false} 
                                                                                prefix={
                                                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </Form>
                                                                    <List
                                                                        locale={{ emptyText: <Empty description="The list is empty" />}} 
                                                                        className="organization_list"
                                                                        dataSource={obj.users}
                                                                        pagination={{
                                                                            defaultPageSize: 10, 
                                                                            hideOnSinglePage: true,
                                                                        }}
                                                                        renderItem={(item) => (
                                                                            <List.Item
                                                                                key={item.id}
                                                                                actions={[
                                                                                    <Button className='submit_mini' loading={app_global.loading} onClick={() => show_tab_users_item(item)}>Edit</Button>
                                                                                ]}
                                                                            >
                                                                                <Tooltip placement="top" title={
                                                                                    <div>
                                                                                        <p><strong>Email: </strong>{item?.email}</p>
                                                                                    </div>
                                                                                }><span className="orgname">{[item?.lastname,item?.firstname].join(' ').replace(/ +/g, ' ').trim()}</span></Tooltip>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="profile_left">
                                        <div className="profile_card">
                                            <div className="profile_avatar">
                                                {profile.loading ?
                                                    <Skeleton.Avatar style={{width:100, height:100}} shape="circle" active bloak />
                                                :
                                                    <img src={`${UPLOADS}/temp/avatar_${profile.data.gender}.jpg`} />
                                                }
                                            </div>
                                            <div className="card_content">
                                                <div className="card_content_body">
                                                    {profile.loading ?
                                                        <Skeleton.Button style={{marginBottom: '20px'}} shape="round" active block={false} />
                                                    :
                                                        <>
                                                            <h1 style={{margin:0}}>{profile.data.firstname}</h1>
                                                            {profile.data.lastname ? <h1>{profile.data.lastname}</h1> : null}
                                                        </>
                                                    }
                                                    {profile.loading ?
                                                            <>
                                                                <Skeleton.Button style={{marginBottom: '5px'}} size="small" shape="round" active block />
                                                                <Skeleton.Button style={{marginBottom: '5px'}} size="small" shape="round" active block />
                                                                <Skeleton.Button style={{marginBottom: '5px'}} size="small" shape="round" active block />
                                                            </>
                                                        :
                                                        <>
                                                            <p><b>Email</b>: {profile.data.email}</p>
                                                        </>
                                                    }
                                                </div>
                                                <div className="card_content_footer">
                                                    { activeTab !== 'profile' ? 
                                                        <Button className="default" loading={app_global.loading} onClick={() => setActiveTab('profile')} >Edit</Button>
                                                    :
                                                        null    
                                                    }
                                                    <Button className="exit" loading={app_global.loading || profile.loading} onClick={() => logout()} >Log out</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="on_auth">
                                    <h1>You are not logged in</h1>
                                    <Link className='ant-btn ant-btn-primary default' to='/' exact={ true }><span>Home</span></Link>
                                </div>
                            }
                        </>
                    }
                </div>
            </section>
        </>
    );
};