import {createSlice} from '@reduxjs/toolkit';

const paysSclice = createSlice({
    name: 'pays',
    initialState: {
        loading: false,
        pay_list_sites: [],
        pay_list: [],
        pay_id: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPay_list_sites: (state, action) => {
            state.pay_list_sites = action.payload
        },
        setPay_list: (state, action) => {
            state.pay_list = action.payload
        },
        setPay_id: (state, action) => {
            state.pay_id = action.payload
        },
    },
});

export const { 
    setLoading,
    setPay_list_sites,
    setPay_list,
    setPay_id,
} = paysSclice.actions
export default paysSclice.reducer;