import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Empty, message, Skeleton, Form, Input, Button, Modal, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { API, UPLOADS } from '../../../params';
import moment from 'moment';
import exportFromJSON from 'export-from-json'
import { 
    getSitesList,
    addSite
} from '../../../API/sites';
import {
    SearchOutlined
} from '@ant-design/icons';
import { MdOutlineNoPhotography } from 'react-icons/md';
import { updPanel } from '../../../redux/app_global/reducer';
import { Error403_Component } from '../../errors/403';

export const SitesList = props => {
    const dispatch = useDispatch();

    const app_global = useSelector(state => state.app_global);
    const profile = useSelector(state => state.profile);
    const sites = useSelector(state => state.sites);

    const [sites_list_search_name, setSites_list_search_name] = useState("");
    const [sites_list_search_engine, setSites_list_search_engine] = useState("");
    const [sites_list_search_copyright, setSites_list_search_copyright] = useState("");
    const [sites_list_search_status, setSites_list_search_status] = useState("publish");
    const [sites_list_search_country, setSites_list_search_country] = useState("");
    const [sites_list_search_stage, setSites_list_search_stage] = useState("");

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(10);
    
    const [form_site_list_search] = Form.useForm();
    const [form_site_add] = Form.useForm();

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    form_site_list_search.setFieldsValue({ 
        search: sites_list_search_name, 
        engine: sites_list_search_engine, 
        copyright: sites_list_search_copyright,
        status: sites_list_search_status,
        country: sites_list_search_country,
        stage: sites_list_search_stage,
    });

    const location = useLocation();

    useEffect(() => {
        if (profile.token) {
            component_start();
        }
    }, [location]);

    useEffect(async () => {
        if (profile.token) {
            component_start();
        }
    }, [profile.token]);

    async function component_start() {
        await getSitesList({

        },dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [getSitesList]`, `color: #A366FF`, json );
            if (json?.code) {
                //Done
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    var obj = {
        get sites_list () {
            if ( sites.site_list.length > 0 ) {
                let list = sites.site_list;

                let search = sites_list_search_name.toLowerCase();
                if (search !== "") {
                    list = sites.site_list.filter( function( item ) {
                        return(
                            item['name'].toLowerCase().indexOf( search ) > -1 ||
                            item['excerpt'].toLowerCase().indexOf( search ) > -1 ||
                            item['domain'].toLowerCase().indexOf( search ) > -1
                        );
                    } );
                }

                if (sites_list_search_engine !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['engine'] === sites_list_search_engine
                        );
                    } );
                }

                if (sites_list_search_copyright !== "") {
                    if (+sites_list_search_copyright === 0) {
                        list = list.filter( function( item ) {
                            return(
                                +item['copyright'] === 0
                            );
                        } );
                    } else {
                        list = list.filter( function( item ) {
                            return(
                                +item['copyright'] >= +sites_list_search_copyright
                            );
                        } );
                    }
                }

                if (sites_list_search_status !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['status'] === sites_list_search_status
                        );
                    } );
                }

                if (sites_list_search_country !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['country'] === sites_list_search_country
                        );
                    } );
                }

                if (sites_list_search_stage !== "") {
                    list = list.filter( function( item ) {
                        return(
                            item['stage'] === sites_list_search_stage
                        );
                    } );
                }

                return list;
            } else {
                return sites.site_list;
            }
        },
        get engine () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Not specified',
                    value: 'none'
                },
                {
                    label: 'HTML',
                    value: 'html'
                },
                {
                    label: 'WordPress',
                    value: 'wordpress'
                },
                {
                    label: 'Web application',
                    value: 'web'
                },
                {
                    label: 'Tilda',
                    value: 'tilda'
                },
                {
                    label: 'Drupal',
                    value: 'drupal'
                }
            ];
        },
        get country () {
            return [
                {value:'',label:'Not selected'},
                {value:'us',label:'America'},
                {value:'kz',label:'Kazakhstan'},
                {value:'ru',label:'Russia'},
                {value:'uz',label:'Uzbekistan'},
            ]
        },
        get copyright () {
            return [
                {
                    label: 'Not selected',
                    value: ''
                },
                {
                    label: 'Yes',
                    value: '1'
                },
                {
                    label: 'No',
                    value: '0'
                }
            ];
        },
        get status () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'Published',
                    value: 'publish',
                    color: '#52c41a'
                },
                {
                    label: 'Draft',
                    value: 'draft',
                    color: '#8c6722'
                },
                {
                    label: 'Cancelled',
                    value: 'cancel',
                    color: '#f5222d'
                 }
            ]
        },
        get stage () {
            return [
                {
                    label: 'Not selected',
                    value: '',
                    color: '#B5B5B5'
                },
                {
                    label: 'The layout is missing',
                    value: '0',
                    color: '#f5222d'    // Красный
                },
                {
                    label: 'The layout is received/in tasks',
                    value: '1',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'At work',
                    value: '2',
                    color: '#8c6722'    // Желтый
                },
                {
                    label: 'On check with the designer',
                    value: '3',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the designer',
                    value: '4',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '5',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '6',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'On check with the manager',
                    value: '7',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Error correction from the manager',
                    value: '8',
                    color: '#1a7ec4'    // Синий
                },
                {
                    label: 'Passed',
                    value: '9',
                    color: '#52c41a'    // Зелёный
                },
            ]
        }
    };

    async function onChangeSearch() {
        var v = form_site_list_search.getFieldsValue();

        console.log(v);

        if (v['search'] !== undefined) {
            setSites_list_search_name(v['search'].replace(/\s+/g,' '));
        }

        if (v['engine'] !== undefined) {
            setSites_list_search_engine(v['engine']);
        }

        if (v['copyright'] !== undefined) {
            setSites_list_search_copyright(v['copyright']);
        }

        if (v['status'] !== undefined) {
            setSites_list_search_status(v['status']);
        }

        if (v['country'] !== undefined) {
            setSites_list_search_country(v['country']);
        }

        if (v['stage'] !== undefined) {
            setSites_list_search_stage(v['stage']);
        }

    }

    function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }
    
    // Открытие модального окна создания сайта
    async function show_site_add() {
        form_site_add.resetFields();
        dispatch( updPanel('site_add') );
    }

    // Закрытие модального окна создания сайта
    async function hide_site_add() {
        dispatch( updPanel('site_add') );
    }

    // Отправка формы добавления сайта
    async function form_site_add_finish(v) {
        v['domain'] = v['domain'].replace(/https?:\/\//g, '').split('/')[0];
        form_site_add.setFieldsValue({domain:v['domain']})

        await addSite(v,dispatch,profile)
        .then(async json => {
            console.log ( `%c [API]: [addSite]`, `color: #A366FF`, json );
            if (json?.code) {
                message.success('The site has been added');
                hide_site_add();
                getSitesList({},dispatch,profile);
                window.open(`/sites/${json?.result}`, "_blank");
            } else {
                let error = [];
                if (json.error[0]) {
                    error.push(json.error[0]);
                }
                if (json.error[1]) {
                    error.push(json.error[1]);
                }
                if (json.error[3]) {
                    error.push((json.error[3]).join(', '));
                }
                message.error(error.join('. '));
            }
        });
    }

    async function siteExport() {
        const data = obj.sites_list.map((item) => {
            let container = {...item};
            container.copyright = +item.copyright ? moment.unix(item.copyright).format("DD/MM/YYYY") : 'no';
            container.engine = obj.engine.find((find_item) => find_item.value === item.engine)?.label;
            container.country = obj.country.find((find_item) => find_item.value === item.country)?.label;
            container.stage = obj.stage.find((find_item) => find_item.value === item.stage)?.label;
            container.date_contract = moment.unix(item.date_contract).format("DD/MM/YYYY");
            //container.paid_up_to = moment.unix(item.date_contract).add('months', +item.months).format("DD/MM/YYYY");
            //container.debt = moment.unix(item.date_contract).add('months', +item.months).unix() > moment().unix() ? 'There is no debt' : 'There is a debt';
            delete container.id;
            delete container.status;
            delete container.months;
            delete container.date_create;
            delete container.date_edit;
        
            return container;
        });

        const fileName = 'List of sites '+moment().format("DD-MM-YYYY HH-mm");
        const exportType =  exportFromJSON.types.csv;
        const fields = {
            name: "Site name",
            domain: "Domain name",
            engine: "Engine",
            excerpt: "Site Description",
            material: "Links to the site materials",
            features: "Site Features",
            contacts: "Website contact details",
            seo: "SEO data sites",
            form: "Where data is sent from the form",
            copyright: "Availability of copyright",
            country: "Country",
            date_contract: "Date of conclusion of the contract",
            stage: "Stage",
            //paid_up_to: "Up to what date is the site paid for",
            //debt: "Is there a debt or not"
        };

        console.table(data);
        exportFromJSON({ data, fileName, fields, exportType })
    }

    return (
        <>
            <Modal mask={false} className="modal_custom" key="m1" width={600} title="Adding a site" open={app_global.panel.indexOf( 'site_add' ) != -1 } onOk={() => hide_site_add()} onCancel={() => hide_site_add()} footer={null}>
                <div className='header'>
                    <h2>Adding a site</h2>
                </div>
                <div className='custom_divider' />
                <div className='body'>
                    <div>
                        <Form name="form" className="default_form" form={form_site_add} onFinish={form_site_add_finish}>
                            <label>Title</label>
                            <Form.Item name={`name`} rules={[{ required: true, message: `Please enter the title!` }]}>
                                <Input disabled={sites.loading} className='custom_input' placeholder={`Title`} />
                            </Form.Item>
                            <label>Domain</label>
                            <Form.Item name={`domain`} rules={[{ required: true, message: `Please enter the domain name!` }]}>
                                <Input disabled={sites.loading} className='custom_input' placeholder={`domain.com`} />
                            </Form.Item>
                            <label>A country</label>
                            <Form.Item name={`country`} rules={[{ required: true, message: `Please select a country!` }]} >
                                <Select
                                    disabled={sites.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Select a country"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.country.filter((country_item) => ['','none'].indexOf( country_item.value ) === -1)}
                                />
                            </Form.Item>
                            <label>Engine</label>
                            <Form.Item name={`engine`} >
                                <Select
                                    disabled={sites.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Select Engine"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.engine.filter((engine_item) => ['','none'].indexOf( engine_item.value ) === -1)}
                                />
                            </Form.Item>
                            <label>Site Stage</label>
                            <Form.Item name={`stage`} >
                                <Select
                                    disabled={sites.loading}
                                    className="custom_select"
                                    showSearch
                                    placeholder="Select stage"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={obj.stage.filter((engine_item) => ['','none'].indexOf( engine_item.value ) === -1)}
                                />
                            </Form.Item>
                            
                            <div className='form_buttons'>
                                <Button className='submit' htmlType="submit" loading={sites.loading}>Save</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
            <section className='container --sites_list shadow default_padding marginTop'>
                <section className='top'>
                    <section className='container shadow default_padding'>
                        <h1>List of sites</h1>
                    </section>
                </section>
                <div className='content'>
                    {app_global.loading ?
                        <Skeleton active />
                    :
                        profile.auth ?
                            <>
                                <div className='left_form'>
                                    <Form name="form" className="default_form" form={form_site_list_search}>
                                        <div className='form_buttons' style={{marginTop:0, marginBottom:20}}>
                                            <Link className="ant-btn ant-btn-default submit" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} exact={ true } to={`/`} ><span>Go back</span></Link>
                                        </div>
                                        <Form.Item key="search" name="search">
                                            <Input disabled={sites.loading} autoFocus className={`custom_input`} placeholder="Search by name" onChange={ () => onChangeSearch() } bordered={false} 
                                                prefix={
                                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 20 }} />
                                                }
                                                suffix={
                                                    ["3","4"].indexOf( profile?.data?.type ) !== -1 ?
                                                        <Button className='submit_mini' onClick={() => show_site_add()}>Add</Button>
                                                    :
                                                        false
                                                }
                                            />
                                        </Form.Item>
                                        <label>A country</label>
                                        <Form.Item name={`country`} >
                                            <Select
                                                disabled={sites.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select a country"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.country}
                                            />
                                        </Form.Item>
                                        <label>Engine</label>
                                        <Form.Item name={`engine`} >
                                            <Select
                                                disabled={sites.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select engine"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={
                                                    obj.engine
                                                }
                                            />
                                        </Form.Item>
                                        <label>Copyright</label>
                                        <Form.Item name={`copyright`} >
                                            <Select
                                                disabled={sites.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Is there a copyright or not"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.copyright}
                                            />
                                        </Form.Item>
                                        <label>Site Stage</label>
                                        <Form.Item name={`stage`} >
                                            <Select
                                                disabled={sites.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select stage"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.stage}
                                            />
                                        </Form.Item>
                                        <label>Status</label>
                                        <Form.Item name={`status`} >
                                            <Select
                                                disabled={sites.loading}
                                                onChange={() => onChangeSearch()}
                                                className="custom_select"
                                                showSearch
                                                placeholder="Select status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={obj.status}
                                            />
                                        </Form.Item>
                                        <div className='form_buttons'>
                                            <Button className='submit' style={{width:'100%'}} onClick={() => siteExport()} loading={sites.loading}>Export to CSV</Button>
                                        </div>
                                    </Form>
                                </div>
                                <div className='right_content'>
                                    {sites.loading ?
                                        <Skeleton active />
                                    :
                                        <List itemLayout="vertical"
                                            size="large"
                                            pagination={{
                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                                onChange(current, pageSize) {
                                                    setPage(current);
                                                    setPaginationSize(pageSize)
                                                },
                                                defaultCurrent: page
                                            }}
                                            locale={{
                                                emptyText: 
                                                    <Empty 
                                                        description="The list of sites is empty"
                                                    />
                                            }}
                                            dataSource={obj.sites_list}
                                            renderItem={(item, index) => (
                                                <List.Item key={index} >
                                                    <a target='_blank' href={`/sites/${item.id}`} >
                                                        <div className='left'>
                                                            <div className={`spec_item_left ${item.site_preview ? item.site_preview : 'no_photo'}`}>
                                                                {item.site_preview ? 
                                                                    <img src={`${API}/uploads/${item.site_preview}`} /> 
                                                                : 
                                                                    <MdOutlineNoPhotography />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className="org_name">
                                                                <span className="name">{item.name}</span>
                                                            </div>
                                                            <div className='org_bottom'>
                                                                <p className='last'>{item.excerpt}</p>
                                                            </div>
                                                            <div className='org_info'>
                                                                <p className='experience'>
                                                                    <span><b>A country: </b>{obj.country.find((find_item) => find_item.value === item.country)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Engine: </b>{obj.engine.find((find_item) => find_item.value === item.engine)?.label}</span>
                                                                </p>
                                                                <p className='experience'>
                                                                    <span><b>Date of copyright protection: </b>{+item.copyright ? moment.unix(item.copyright).format("DD/MM/YYYY") : 'no'}</span>
                                                                </p>
                                                                <p className='category'><span style={{backgroundColor:obj.status.find((find_item) => find_item.value === item.status)?.color}}>{obj.status.find((find_item) => find_item.value === item.status)?.label}</span><span style={{backgroundColor:obj.stage.find((find_item) => find_item.value === item.stage)?.color}}>{obj.stage.find((find_item) => find_item.value === item.stage)?.label}</span><span>{item.domain}</span></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </div>
                            </>
                        :
                            <Error403_Component />
                    }
                </div>
            </section>
        </>
    );
};