import {createSlice} from '@reduxjs/toolkit';

const profileSclice = createSlice({
    name: 'profile',
    initialState: {
        loading: false,
        auth: 0,
        token: '',
        data: {},
        registration: {},
        users: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        deleteData: (state, action) => {
            state.data = {};
        },
        setRegistration: (state, action) => {
            state.registration = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
    },
});

export const { 
    setLoading,
    setAuth,
    setToken,
    setData, deleteData,
    setRegistration,
    setUsers
} = profileSclice.actions
export default profileSclice.reducer;